export class Annotation {
  //id: string;
  userId: string;
  projectId: string;
  text: string;
  date: string;
  nameOfGarph: string;
  internalUrl :string;
  internalUrlTitle :string;
}
