<h2 mat-dialog-title>Filter metric data</h2>
<mat-dialog-content style="width: 100%">
  <div style="display: flex; flex-direction: column">
    <div style="display: flex; flex-direction: column">
      <label style="flex: 1">Start Date:</label>
      <input
        style="flex: 4"
        matInput
        type="date"
        name=""
        id=""
        [(ngModel)]="start"
      />
    </div>
    <div style="display: flex; flex-direction: column; margin-top: 4%">
      <label style="flex: 1">End Date:</label>
      <input style="flex: 4" type="date" name="" id="" [(ngModel)]="end" />
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="margin-left: 3%">
  <button mat-button mat-dialog-close (click)="applyFilter()" color="primary">
    Apply Filter
  </button>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
