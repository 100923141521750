import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { Annotation } from 'src/app/shared/models/annotation.model';

@Injectable({
  providedIn: 'root',
})
export class AnnotationService {
  apiRoutes: any;

  constructor(private httpClient: HttpClient) {
    this.apiRoutes = ConstantService.apiRoute;
  }
  getAnnotation(projectId, nameOfGarph ,internalUrl): Observable<Annotation> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${this.auth_token}`,
    });
    return this.httpClient.get<Annotation>(
      this.apiRoutes.annotation +
        '/' +
        projectId +
        '/' +
        nameOfGarph+
        '/' +
        internalUrl, {
          'headers': headers,
        }
        );
  }
  postAnnotation(data): Observable<Annotation> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${this.auth_token}`,
    });
    console.log('url', this.apiRoutes.annotation);
    return this.httpClient.post<Annotation>(this.apiRoutes.annotation, data, {
      'headers': headers,
    });
  }
  constructAnnotation(data,type) {
    let annotationData: Array<object> = [];
    data.map((ele) => {
     if (type =="barChart"){
        annotationData.push(this.modelAnnotationBarChart(ele)[0]);

      }else{
        annotationData.push(this.modelAnnotaionLineChart(ele)[0]);
      }
    });
    return annotationData;
  }

  modelAnnotationBarChart(data: Annotation) {
    return [
      {
        x: data.date,
        y: 100,
        seriesIndex: 0,
        label: {
          borderColor: '#775DD0',
          offsetY: 0,
          style: {
            color: '#fff',
            background: '#775DD0',
          },
          text: data.text,
        },
      },
    ];
  }
  modelAnnotaionLineChart(data: Annotation){
    return  [
      {
        x: data.date,
        strokeDashArray: 0,
        borderColor: '#775DD0',
        label: {
          borderColor: '#775DD0',
          style: {
            color: '#fff',
            background: '#775DD0',
          },
          text: data.text,
        },
      },
    ]
  }
}
