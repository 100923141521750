export const CountryCodes = {
    Afghanistan: 'af',
    AlandIslands: 'ax',
    Albania: 'al',
    Algeria: 'dz',
    AmericanSamoa: 'as',
    Andorra: 'ad',
    Angola: 'ao',
    Anguilla: 'ai',
    Antarctica: 'aq',
    AntiguaAndBarbuda: 'ag',
    Argentina: 'ar',
    Armenia: 'am',
    Aruba: 'aw',
    Australia: 'au',
    Austria: 'at',
    Azerbaijan: 'az',
    Bahamas: 'bs',
    Bahrain: 'bh',
    Bangladesh: 'bd',
    Barbados: 'bb',
    Belarus: 'by',
    Belgium: 'be',
    Belize: 'bz',
    Benin: 'bj',
    Bermuda: 'bm',
    Bhutan: 'bt',
    Bolivia: 'bo',
    BonaireSintEustatiusSaba: 'bq',
    BosniaAndHerzegovina: 'ba',
    Botswana: 'bw',
    BouvetIsland: 'bv',
    Brazil: 'br',
    BritishIndianOceanTerritory: 'io',
    BruneiDarussalam: 'bn',
    Bulgaria: 'bg',
    BurkinaFaso: 'bf',
    Burundi: 'bi',
    Cambodia: 'kh',
    Cameroon: 'cm',
    Canada: 'ca',
    CapeVerde: 'cv',
    CaymanIslands: 'ky',
    CentralAfricanRepublic: 'cf',
    Chad: 'td',
    Chile: 'cl',
    China: 'cn',
    ChristmasIsland: 'cx',
    CocosKeelingIslands: 'cc',
    Colombia: 'co',
    Comoros: 'km',
    Congo: 'cg',
    CongoDemocraticRepublic: 'cd',
    CookIslands: 'ck',
    CostaRica: 'cr',
    CoteDIvoire: 'ci',
    Croatia: 'hr',
    Cuba: 'cu',
    Curaçao: 'cw',
    Cyprus: 'cy',
    CzechRepublic: 'cz',
    Denmark: 'dk',
    Djibouti: 'dj',
    Dominica: 'dm',
    DominicanRepublic: 'do',
    Ecuador: 'ec',
    Egypt: 'eg',
    ElSalvador: 'sv',
    EquatorialGuinea: 'gq',
    Eritrea: 'er',
    Estonia: 'ee',
    Ethiopia: 'et',
    FalklandIslands: 'fk',
    FaroeIslands: 'fo',
    Fiji: 'fj',
    Finland: 'fi',
    France: 'fr',
    FrenchGuiana: 'gf',
    FrenchPolynesia: 'pf',
    FrenchSouthernTerritories: 'tf',
    Gabon: 'ga',
    Gambia: 'gm',
    Georgia: 'ge',
    Germany: 'de',
    Ghana: 'gh',
    Gibraltar: 'gi',
    Greece: 'gr',
    Greenland: 'gl',
    Grenada: 'gd',
    Guadeloupe: 'gp',
    Guam: 'gu',
    Guatemala: 'gt',
    Guernsey: 'gg',
    Guinea: 'gn',
    GuineaBissau: 'gw',
    Guyana: 'gy',
    Haiti: 'ht',
    HeardIslandMcdonaldIslands: 'hm',
    HolySeeVaticanCityState: 'va',
    Honduras: 'hn',
    HongKong: 'hk',
    Hungary: 'hu',
    Iceland: 'is',
    India: 'in',
    Indonesia: 'id',
    Iran: 'ir',
    Iraq: 'iq',
    Ireland: 'ie',
    IsleOfMan: 'im',
    Israel: 'il',
    Italy: 'it',
    Jamaica: 'jm',
    Japan: 'jp',
    Jersey: 'je',
    Jordan: 'jo',
    Kazakhstan: 'kz',
    Kenya: 'ke',
    Kiribati: 'ki',
    Korea: 'kr',
    KoreaDemocraticPeoplesRepublic: 'kp',
    Kuwait: 'kw',
    Kyrgyzstan: 'kg',
    LaoPeoplesDemocraticRepublic: 'la',
    Latvia: 'lv',
    Lebanon: 'lb',
    Lesotho: 'ls',
    Liberia: 'lr',
    LibyanArabJamahiriya: 'ly',
    Liechtenstein: 'li',
    Lithuania: 'lt',
    Luxembourg: 'lu',
    Macao: 'mo',
    Macedonia: 'mk',
    Madagascar: 'mg',
    Malawi: 'mw',
    Malaysia: 'my',
    Maldives: 'mv',
    Mali: 'ml',
    Malta: 'mt',
    MarshallIslands: 'mh',
    Martinique: 'mq',
    Mauritania: 'mr',
    Mauritius: 'mu',
    Mayotte: 'yt',
    Mexico: 'mx',
    Micronesia: 'fm',
    Moldova: 'md',
    Monaco: 'mc',
    Mongolia: 'mn',
    Montenegro: 'me',
    Montserrat: 'ms',
    Morocco: 'ma',
    Mozambique: 'mz',
    Myanmar: 'mm',
    Namibia: 'na',
    Nauru: 'nr',
    Nepal: 'np',
    Netherlands: 'nl',
    NewCaledonia: 'nc',
    NewZealand: 'nz',
    Nicaragua: 'ni',
    Niger: 'ne',
    Nigeria: 'ng',
    Niue: 'nu',
    NorfolkIsland: 'nf',
    NorthernMarianaIslands: 'mp',
    Norway: 'no',
    Oman: 'om',
    Pakistan: 'pk',
    Palau: 'pw',
    PalestinianTerritory: 'ps',
    Panama: 'pa',
    PapuaNewGuinea: 'pg',
    Paraguay: 'py',
    Peru: 'pe',
    Philippines: 'ph',
    Pitcairn: 'pn',
    Poland: 'pl',
    Portugal: 'pt',
    PuertoRico: 'pr',
    Qatar: 'qa',
    Reunion: 're',
    Romania: 'ro',
    RussianFederation: 'ru',
    Rwanda: 'rw',
    SaintBarthelemy: 'bl',
    SaintHelena: 'sh',
    SaintKittsAndNevis: 'kn',
    SaintLucia: 'lc',
    SaintMartin: 'mf',
    SaintPierreAndMiquelon: 'pm',
    SaintVincentAndGrenadines: 'vc',
    Samoa: 'ws',
    SanMarino: 'sm',
    SaoTomeAndPrincipe: 'st',
    SaudiArabia: 'sa',
    Senegal: 'sn',
    Serbia: 'rs',
    Seychelles: 'sc',
    SierraLeone: 'sl',
    Singapore: 'sg',
    SintMaarten: 'sx',
    Slovakia: 'sk',
    Slovenia: 'si',
    SolomonIslands: 'sb',
    Somalia: 'so',
    SouthAfrica: 'za',
    SouthGeorgiaAndSandwichIsl: 'gs',
    SouthSudan: 'ss',
    Spain: 'es',
    SriLanka: 'lk',
    Sudan: 'sd',
    Suriname: 'sr',
    SvalbardAndJanMayen: 'sj',
    Swaziland: 'sz',
    Sweden: 'se',
    Switzerland: 'ch',
    SyrianArabRepublic: 'sy',
    Taiwan: 'tw',
    Tajikistan: 'tj',
    Tanzania: 'tz',
    Thailand: 'th',
    TimorLeste: 'tl',
    Togo: 'tg',
    Tokelau: 'tk',
    Tonga: 'to',
    TrinidadAndTobago: 'tt',
    Tunisia: 'tn',
    Turkey: 'tr',
    Turkmenistan: 'tm',
    TurksAndCaicosIslands: 'tc',
    Tuvalu: 'tv',
    Uganda: 'ug',
    Ukraine: 'ua',
    UnitedArabEmirates: 'ae',
    UnitedKingdom: 'gb',
    UnitedStates: 'us',
    UnitedStatesOutlyingIslands: 'um',
    Uruguay: 'uy',
    Uzbekistan: 'uz',
    Vanuatu: 'vu',
    Venezuela: 've',
    Vietnam: 'vn',
    VirginIslandsBritish: 'vg',
    VirginIslandsUS: 'vi',
    WallisAndFutuna: 'wf',
    WesternSahara: 'eh',
    Yemen: 'ye',
    Zambia: 'zm',
    Zimbabwe: 'zw',
};