import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';


export function belongOriginDomainValidator(originDomain): ValidatorFn {
  return(control:AbstractControl) : ValidationErrors | null => {
    const value = control.value

    if (!value)
      return null;

    let x = originDomain.value.includes('www') ? 12 : 8;
    const belongOriginDomain = value.includes(originDomain.value.slice(x))
    return belongOriginDomain ? null : { belongErrors : true }
    }
}

export function diffFromOriginDomainValidator(originDomain): ValidatorFn {
  return(control:AbstractControl) : ValidationErrors | null => {
    const value = control.value
    if (!value)
      return null;

    const diffFromOriginDomain = (value !== originDomain.value)
    return diffFromOriginDomain ?  null : { diffErrors : true  }
    }
}
