import { Injectable } from '@angular/core';
import { Stripe } from "@stripe/stripe-js";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  stripePromise: Promise<Stripe>

  constructor() { 
    this.stripePromise = this.loadStripe();
  }

  private loadStripe(): Promise<Stripe> {
    return (window as any).Stripe(environment.stripeKey);
  }

  async redirectToCheckout(data: any): Promise<void>{
    const stripe = await this.stripePromise;

    const response = await fetch(environment.apiUrl+"/create-checkout-session", {
          method: "POST",
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const session = await response.json();

    const result = await stripe.redirectToCheckout({
      sessionId: session.sessionId
    })

    if(result.error){
      console.log(result.error)
    }
  }
}
