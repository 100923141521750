const good = 'good';
const moderate = 'moderate';
const poor = 'poor';

export let legend = {
  cls :  {
    good : good + ' < 0.1',
    moderate : moderate,
    poor : poor + ' > 0.25',
  },
  lcp :  {
    good : good + ' < 2.5s',
    moderate : moderate,
    poor : poor + ' > 4.0s',
  },
  fid :  {
    good : good + ' < 100 ms',
    moderate : moderate,
    poor : poor + ' > 300 ms',
  },
  fcp :  {
    good : good + ' < 1.8 s',
    moderate : moderate,
    poor : poor + ' > 3.0 s',
  },
  ttfb :  {
    good : good + ' < 0.8s',
    moderate : moderate,
    poor : poor + ' >= 1.8s',
  },
  inp :  {
    good : good + ' <= 200ms',
    moderate : moderate + ' > 200ms and <= 500ms',
    poor : poor + ' > 500ms',
  }
}
