import { Injectable } from '@angular/core';
import { flatten, groupBy, isInteger, keys, uniqBy } from 'lodash';
import { legend } from '../shared/models/legend';
import { PageSpeedInsigthService } from './common/pagespeedinsigth/page-speed-insigth.service';

const mappings = {
  browserName: {
    id: 'browserName',
    name: 'Browser',
  },
  effectiveConnectionType: {
    id: 'ect',
    name: 'Effective Connection Type',
  },
  os: {
    id: 'os',
    name: 'Operating System',
  },
};

const metricFields = {
  default: ['LCP', 'CLS', 'INP'],
  all: ['LCP', 'CLS', 'FCP', 'TTFB', 'INP'],
  cls: ['CLS'],
  lcp: ['LCP'],
  fcp: ['FCP'],
  ttfb: ['TTFB'],
  inp: ['INP'],
};

const fieldMappings = {
  CLS: 'cls',
  LCP: 'lcps',
  FCP: 'fcps',
  TTFB: 'ttfbs',
  INP: 'inps',
};

@Injectable({
  providedIn: 'root',
})
export class FirstPartyDataServiceService {
  constructor(private pageSpeedInsightService: PageSpeedInsigthService) {}
  getDynamicDevices(results) {
    return uniqBy(results[0].metricData || {}, 'deviceType').map(
      (d: any) => d.deviceType,
    );
  }

  getDynamicDimensions(results) {
    const sData = results[0].metricData || {};
    const segmentByDevice = groupBy(sData, 'deviceType');
    const processedFields = {};
    keys(segmentByDevice).forEach((device) => {
      ['effectiveConnectionType', 'os', 'browserName'].forEach((dimGroup) => {
        const dimensions = uniqBy(segmentByDevice[device], dimGroup)
          .map((e) => e[dimGroup])
          .filter((e) => !!e);
        if (!processedFields[device]) processedFields[device] = [];
        processedFields[device].push({
          id: mappings[dimGroup].id,
          name: mappings[dimGroup].name,
          dimensions,
        });
      });
    });
    return processedFields;
  }

  processListOfMetricItems(
    data: any[],
    device: string,
    dimensionGroup = 'all',
    dimension = 'all',
    listOfFields = 'default',
  ) {
    return data?.map((item) => {
      const metricRawData =
        dimensionGroup === 'all'
          ? item?.metricData?.filter((i) => i.deviceType === device)
          : item?.metricData?.filter(
              (i) =>
                i.deviceType === device &&
                i[
                  dimensionGroup === 'ect'
                    ? 'effectiveConnectionType'
                    : dimensionGroup
                ] === dimension,
            );

      const comptedMetric = this.computeMetrics(metricRawData, true);

      const rslt = {};
      metricFields[listOfFields].forEach((field) => {
        const transformed = this.transformDataForChart(comptedMetric[field]);

        rslt[fieldMappings[field]] = transformed
          ? {
              ...this.pageSpeedInsightService.chartOptions(transformed),
              p75: comptedMetric[field].p75,
            }
          : null;
        rslt['total'] = item.pageViews || 0;
      });
      return {
        id: item.id,
        title: item.title,
        website: item.url || item?._id,
        location: item.location,
        siteId: item.projectId,
        ...rslt,
      };
    });
  }

  processInternalOvertime(
    data: any[],
    device: string,
    dimensionGroup = 'all',
    dimension = 'all',
    filter?
  ) {
    const barchartData = {};
    const p75OverTime = {};

    const dates = data.map((d) => d.date);
    const allMetric = data.map((d) => {
      const grouped = groupBy(d.metricData, 'eventType');
      groupBy;
      const m = {};
      keys(grouped).forEach((k: string) => {
        m[k.toLowerCase()] = grouped[k];
      });
      return m;
    });

    metricFields.all.forEach((metric) => {
      const result = this.processMetricBarchartData(
        allMetric,
        metric,
        device,
        dimensionGroup,
        dimension,
      );

      let p75Data = this.internalP75(
        allMetric,
        metric,
        device,
        dimensionGroup,
        dimension,
      );

      p75Data = p75Data?.map((d) => d || 0)?.map((d) => d.toFixed(2));

      p75OverTime[metric.toLowerCase()] =
        this.pageSpeedInsightService.chartOptionsmetricOverTime(
          'P75 evolution',
          p75Data,
          dates,
          {},
          filter
        );
      const good = result.map((r) =>
        ((r.good * 100 || 0) / (r.total || 1)).toFixed(1),
      );
      const needsImprovement = result.map((r) =>
        ((r.average * 100 || 0) / (r.total || 1)).toFixed(1),
      );
      const poor = result.map((r) =>
        ((r.poor * 100 || 0) / (r.total || 1)).toFixed(1),
      );

      const legendData = legend[metric.toLowerCase()];

      const barchartDataMetric = {
        good,
        needsImprovement,
        poor,
        p75: p75Data,
        month: dates,
        date: dates,
        nameGood: legendData.good,
        nameNeedsImprovement: legendData.moderate,
        namePoor: legendData.poor,
      };
      barchartData[metric.toLowerCase()] =
        this.pageSpeedInsightService.chartOptionCrux(barchartDataMetric, {},filter);
    });
    return { p75OverTime, barchartData };
  }

  transformDataForChart(dataPoint) {
    // const dataPoint = data.find((a) => a.eventType === prefix);
    if (!dataPoint) return null;
    const { good, average, poor, total } = dataPoint;
    return {
      good: ((good || 0) / (total || 1)) * 100,
      needsImprovement: ((average || 0) / (total || 1)) * 100,
      poor: ((poor || 0) / (total || 1)) * 100,
    };
  }

  processMetricBarchartData(
    data: any[],
    metric: string,
    device: string,
    dimensionGroup = 'all',
    dimension = 'all',
  ) {
    const allRaw = data
      .map((d) => d[metric.toLowerCase()] || [])
      .filter((i) => i || 0);
    return allRaw.map((item) => {
      const metricRawData =
        dimensionGroup === 'all'
          ? item.filter((i) => i.deviceType === device)
          : item.filter(
              (i) =>
                i.deviceType === device &&
                i[
                  dimensionGroup === 'ect'
                    ? 'effectiveConnectionType'
                    : dimensionGroup
                ] === dimension,
            );
      const computedData = this.computeMetrics(metricRawData, true)[metric];
      return !!computedData
        ? computedData
        : {
            good: 0,
            average: 0,
            poor: 0,
            total: 0,
            p25: 0,
            p50: 0,
            p75: 0,
            p95: 0,
          };
    });
  }

  internalP75(data, metric, device, dimensionGroup, dimension) {
    const allRaw = data
      .map((d) => d[metric.toLowerCase()] || [])
      .filter((i) => i || 0);
    return allRaw.map((item) => {
      const metricRawData =
        dimensionGroup === 'all'
          ? item.filter((i) => i.deviceType === device)
          : item.filter(
              (i) =>
                i.deviceType === device &&
                i[
                  dimensionGroup === 'ect'
                    ? 'effectiveConnectionType'
                    : dimensionGroup
                ] === dimension,
            );
      const p75DataList = metricRawData.map((i) => i.all_data);
      // console.log('P&%', lodash.flatMap(p75DataList));
      return this.getPercentileValue(flatten(p75DataList), 0.75);
    });
  }

  computeMetrics(metricRawData, includeP = false) {
    const computedData = {};
    const grouped = groupBy(metricRawData, 'eventType');
    keys(grouped).forEach((eventType) => {
      const groupArray = grouped[eventType];
      let good = 0,
        average = 0,
        poor = 0,
        total = 0;
      let p25 = 0;
      let p75 = 0;
      let p50 = 0;
      let p95 = 0;
      let p25thDataList = [];
      let p75thDataList = [];
      let p50thDataList = [];
      let p95thDataList = [];
      groupArray.forEach((i) => {
        good += i.good || 0;
        average += i.average || 0;
        poor += i.poor || 0;
        total += i.total || 0;
        if (includeP) {
          p25thDataList = p25thDataList.concat(i.all_data || []);
          p50thDataList = p50thDataList.concat(i.all_data || []);
          p75thDataList = p75thDataList.concat(i.all_data || []);
          p95thDataList = p95thDataList.concat(i.all_data || []);
        }
      });
      if (includeP) {
        p25 = this.getPercentileValue(p25thDataList, 0.25);
        p50 = this.getPercentileValue(p50thDataList, 0.5);
        p75 = this.getPercentileValue(p75thDataList, 0.75);
        p95 = this.getPercentileValue(p95thDataList, 0.95);
      }
      computedData[eventType] = {
        good,
        average,
        poor,
        total,
        ...(includeP && { p25, p75, p50, p95 }),
      };
    });
    return computedData;
  }

  getPercentileValue(array: any[], percentile: number) {
    array.sort();
    let k = percentile * (array.length - 1);
    if (isInteger(k)) return array[k];

    const kfloor = Math.floor(k);
    const kceil = Math.ceil(k);
    const lLow = array[kfloor];
    const lHigh = array[kceil];

    return lLow + (k - kfloor) * (lHigh - lLow);
  }
}
