import { Injectable } from '@angular/core';
import { filter, omit } from 'lodash';
import { PageSpeedInsigthService } from './common/pagespeedinsigth/page-speed-insigth.service';
import { legend } from '../shared/models/legend';

@Injectable({
  providedIn: 'root',
})
export class RumService {
  constructor(private pageSpeed: PageSpeedInsigthService) {}

  getComputedRumSitesList(
    rumSites: any[],
    dimensionGroup,
    dimension,
    device,
    location
  ) {
    const result = [];
    for (const rumSite of rumSites) {
      const filteredData = rumSite.metricData.filter((md) => {
        const dimAndDeviceFilter =
          md.device === device &&
          md.dimensionGroup === dimensionGroup &&
          md.dimension === dimension;

        return location === 'All'
          ? dimAndDeviceFilter
          : dimAndDeviceFilter && md.location === location;
      });

      for (const fil of filteredData) {
        const ro = {};
        fil.calculated.forEach((c) => {
          ro[c._id.toLowerCase()] = {
            ...this.pageSpeed.chartOptions({
              good: c.pctGood * 100,
              needsImprovement: c.pctNeedsImprovement * 100,
              poor: c.pctPoor * 100,
            }),
            p75: c.percentiles[2],
          };
        });
        result.push({
          website: rumSite.url,
          siteId: rumSite.projectId,
          title: rumSite.title,
          location: fil.location,
          ...ro,
        });
      }
    }

    return result;
  }

  getComputedOverview(rumSite, dimensionGroup, dimension, device, location) {
    const data = rumSite[0].metricData.find(
      (md) =>
        md.device === device &&
        md.dimensionGroup === dimensionGroup &&
        md.dimension === dimension &&
        md.location === location
    );
  
    const data1 = rumSite.length > 1 ? rumSite[1].metricData.find(
    (md) =>
      md.device === device &&
      md.dimensionGroup === dimensionGroup &&
      md.dimension === dimension &&
      md.location === location
    ):null ;
    const metric = {};
    data?.calculated?.forEach((c) => { 
      const c1 = data1 ? data1.calculated.find((c2) => c2._id === c._id):null;
      metric[c._id.toLowerCase()] = {
        ...this.pageSpeed.chartOptions({
          good: c.pctGood * 100,
          needsImprovement: c.pctNeedsImprovement * 100,
          poor: c.pctPoor * 100,
        }),
        p75: c.percentiles[2],
      };
      if (c1) {
        metric[c1._id.toLowerCase()].diff = parseFloat(((c.pctGood - c1.pctGood) * 100).toFixed(2));
      }
    });
    return metric;
  }

  getComputedMetric(historicData, dimensionGroup, dimension, device, location) {
    return historicData.map((hd) => {
      const metricData = hd.metricData.find(
        (md) =>
          md.device === device &&
          md.dimensionGroup === dimensionGroup &&
          md.dimension === dimension &&
          md.location === location
      );
      return {
        ...hd,
        metricData,
      };
    });
  }

  getComputedRUMInternalPagesList(
    pagesData,
    dimensionGroup,
    dimension,
    device,
    location,
    metrics = ['LCP', 'CLS', 'INP', 'TTFB', 'FCP']
  ) {
    return pagesData.map((item) => {
      const filteredMetric = item?.metricData?.filter(
        (md) =>
          md.device === device &&
          md.dimensionGroup === dimensionGroup &&
          md.dimension === dimension &&
          md.location === location
      );

      const rslt = {};
      metrics
        .map((m) => m.toLowerCase())
        .forEach((field) => {
          rslt[field] = filteredMetric?.length
            ? {
                ...this.pageSpeed.chartOptions({
                  good:
                    filteredMetric?.[0]?.calculated?.find(
                      (e) => e._id?.toLowerCase() == field
                    )?.pctGood * 100 || 0,
                  needsImprovement:
                    filteredMetric?.[0]?.calculated?.find(
                      (e) => e._id.toLowerCase() == field
                    )?.pctNeedsImprovement * 100 || 0,
                  poor:
                    filteredMetric?.[0]?.calculated?.find(
                      (e) => e._id.toLowerCase() == field
                    )?.pctPoor * 100 || 0,
                }),
                p75:
                  filteredMetric?.[0]?.calculated?.find(
                    (e) => e._id.toLowerCase() == field
                  )?.percentiles?.[2] || 0,
              }
            : null;
        });

      return {
        id: item.id,
        title: item.title,
        website: item.url || item?._id,
        location: item.location,
        siteId: item.projectId,
        total: item.pageVisits,
        ...rslt,
      };
    });
  }

  getRUMWorstPerfomingPages(
    data,
    dimensionGroup,
    dimension,
    device,
    location,
    metrics
  ) {
    return data
      .filter(
        (md) =>
          md.device === device &&
          md.dimensionGroup === dimensionGroup &&
          md.dimension === dimension &&
          md.location === location
      )
      .map((d) => {
        const rslt = {};
        metrics.forEach((field) => {
          rslt[field.toLowerCase()] = {
            ...this.pageSpeed.chartOptions({
              good: d?.pctGood * 100 || 0,
              needsImprovement: d?.pctNeedsImprovement * 100 || 0,
              poor: d?.pctPoor * 100 || 0,
            }),
          };
        });
        return { website: d?.url, pageVisits: d?.pageVisits, ...rslt };
      });
  }

  getRUMInternalPageDetailAll(
    data,
    dimensionGroup,
    dimension,
    device,
    location,
    metrics
  ) {
    const reslt = {};
    const filteredData = data.metricData.find(
      (md) =>
        md.device === device &&
        md.dimensionGroup === dimensionGroup &&
        md.dimension === dimension &&
        md.location === location
    );
    if (!filteredData) return {};

    const calculated = filteredData.calculated;

    metrics.forEach((metric) => {
      reslt[metric] = !!calculated?.find((d) => d._id == metric?.toUpperCase())
        ? {
            ...this.pageSpeed.chartOptions({
              good:
                calculated?.find((d) => d._id == metric?.toUpperCase())
                  ?.pctGood * 100 || 0,
              needsImprovement:
                calculated?.find((d) => d._id == metric.toUpperCase())
                  ?.pctNeedsImprovement * 100 || 0,
              poor:
                calculated?.find((d) => d._id == metric.toUpperCase())
                  ?.pctPoor * 100 || 0,
            }),
            p75: calculated?.find((d) => d._id == metric.toUpperCase())
              ?.percentiles?.[2],
          }
        : null;
    });
    return reslt;
  }
  getRUMInternalPageDetailAllForCompare(
    siteTitle1,
    siteTitle2,
    data1,
    data2,
    dimensionGroup,
    dimension,
    device,
    location,
    metrics
  ) {
    const reslt = {};
    const filteredData1 = data1.metricData.find(
      (md) =>
        md.device === device &&
        md.dimensionGroup === dimensionGroup &&
        md.dimension === dimension &&
        md.location === location
    );
    const filteredData2 = data2.metricData.find(
      (md) =>
        md.device === device &&
        md.dimensionGroup === dimensionGroup &&
        md.dimension === dimension &&
        md.location === location
    );

    if (!filteredData1) return {};

    const calculated1 = filteredData1.calculated;
    const calculated2 = filteredData2.calculated;

    metrics.forEach((metric) => {
      const data1 = calculated1?.find((d) => d._id == metric?.toUpperCase());
      const data2 = calculated2?.find((d) => d._id == metric?.toUpperCase());
    
      reslt[metric] = data1 && data2
        ? {
            ...this.pageSpeed.chartOptionsForCompare({
              good: [
                (data1?.pctGood || 0) * 100,
                (data2?.pctGood || 0) * 100
              ],
              needsImprovement: [
                (data1?.pctNeedsImprovement || 0) * 100,
                (data2?.pctNeedsImprovement || 0) * 100
              ],
              poor: [
                (data1?.pctPoor || 0) * 100,
                (data2?.pctPoor || 0) * 100
              ],
            },siteTitle1,siteTitle2),
            p75: [data1?.percentiles?.[2], data2?.percentiles?.[2]],
          }
        : null;
    });
    return reslt;
  }
  getRUMInternalPageDetailOvertime(
    data,
    dimensionGroup,
    dimension,
    device,
    location,
    queryPeriod
  ) {
    const dates = data.map((d) => d.date);

    const filteredData = data?.map(
      (d) =>
        d.metricData.find(
          (md) =>
            md.device === device &&
            md.dimensionGroup === dimensionGroup &&
            md.dimension === dimension &&
            md.location === location
        )?.calculated
    );
    const barchartData = {};
    const p75OverTime = {};

    ['ttfb', 'fcp', 'lcp', 'cls', 'inp',].forEach((metric) => {
      const p75Data = [];
      const good = [];
      const needsImprovement = [];
      const poor = [];

      filteredData.forEach((fd) => {
        const md = fd.find((d) => d._id.toLowerCase() == metric);
        p75Data.push(md?.percentiles?.[2]?.toFixed(2) || 0);
        good.push((md?.pctGood * 100 || 0).toFixed(2));
        needsImprovement.push((md?.pctNeedsImprovement * 100 || 0).toFixed(2));
        poor.push((md?.pctPoor * 100 || 0).toFixed(2));
      });

      const legendData = legend[metric];

      p75OverTime[metric] = this.pageSpeed.chartOptionsmetricOverTime(
        'P75 evolution',
        p75Data,
        dates,
        {},
        queryPeriod
      );

      const barchartDataMetric = {
        good,
        needsImprovement,
        poor,
        p75: p75Data,
        month: dates,
        date: dates,
        nameGood: legendData.good,
        nameNeedsImprovement: legendData.moderate,
        namePoor: legendData.poor,
      };
      barchartData[metric] = this.pageSpeed.chartOptionCrux(
        barchartDataMetric,
        {},
        queryPeriod
      );
    });

    return { p75OverTime, barchartData };
  }
  getRUMInternalPageDetailOvertimeForCompare(
    dataname1,
    dataname2,
    data1,
    data2,
    dimensionGroup,
    dimension,
    device,
    location,
    queryPeriod
  ) {
    const dates1 = data1.map((d) => d.date);
    const dates2 = data2.map((d) => d.date);

    const filteredData1 = data1?.map(
      (d) =>
        d.metricData.find(
          (md) =>
            md.device === device &&
            md.dimensionGroup === dimensionGroup &&
            md.dimension === dimension &&
            md.location === location
        )?.calculated
    );
    const filteredData2 = data2?.map(
      (d) =>
        d.metricData.find(
          (md) =>
            md.device === device &&
            md.dimensionGroup === dimensionGroup &&
            md.dimension === dimension &&
            md.location === location
        )?.calculated
    );
    const barchartData = {};
    const p75OverTime = {};

    ['ttfb', 'fcp', 'lcp', 'cls', 'inp',].forEach((metric) => {
      const p75Data1 = [];
      const good1= [];
      const needsImprovement1 = [];
      const poor1 = [];
      const p75Data2 = [];
      const good2 = [];
      const needsImprovement2 = [];
      const poor2 = [];

      filteredData1.forEach((fd) => {
        const md = fd?.find((d) => d._id.toLowerCase() == metric);
        p75Data1.push(md?.percentiles?.[2]?.toFixed(2) || 0);
        good1.push((md?.pctGood * 100 || 0).toFixed(2));
        needsImprovement1.push((md?.pctNeedsImprovement * 100 || 0).toFixed(2));
        poor1.push((md?.pctPoor * 100 || 0).toFixed(2));
      });
      filteredData2.forEach((fd) => {
        const md = fd?.find((d) => d._id.toLowerCase() == metric);
        p75Data2.push(md?.percentiles?.[2]?.toFixed(2) || 0);
        good2.push((md?.pctGood * 100 || 0).toFixed(2));
        needsImprovement2.push((md?.pctNeedsImprovement * 100 || 0).toFixed(2));
        poor2.push((md?.pctPoor * 100 || 0).toFixed(2));
      });

      const legendData = legend[metric];

      p75OverTime[metric] = this.pageSpeed.chartOptionsmetricOverTimeForCompare(
        'P75 evolution',
        dataname1,
        dataname2,
        p75Data1,
        p75Data2,
        dates1,
        {},
        queryPeriod
      );

      const barchartDataMetric = {
        good1,
        needsImprovement1,
        poor1,
        good2,
        needsImprovement2,
        poor2,
        p75: p75Data1,
        month: dates1,
        date: dates1,
        nameGood1: dataname1 + " good",
        nameNeedsImprovement1: dataname1 + " average",
        namePoor1: dataname1 + " poor",
        nameGood2: dataname2 + " good",
        nameNeedsImprovement2: dataname2 + " average" ,
        namePoor2: dataname2 + " poor" ,
      };
      barchartData[metric] = this.pageSpeed.chartOptionCruxForCompare(
        barchartDataMetric,
        {},
        queryPeriod
      );
    });

    return { p75OverTime, barchartData };
  }
}
