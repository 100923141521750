import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-days-range-selector',
  templateUrl: './days-range-selector.component.html',
  styleUrl: './days-range-selector.component.scss',
})
export class DaysRangeSelectorComponent {
  @Output() selectionChange = new EventEmitter();
  @Input() startDate;
  @Input() endDate;
  numberOfDataPoints = 15;
  start: Date;
  end: Date;

  applyFilter() {
    this.selectionChange.emit({
      numberOfDataPoints: this.numberOfDataPoints,
      startDate: this.start,
      endDate: this.end,
    });
  }
}
