<div>
  <nav class="mx-4 mt-3" aria-label="breadcrumb">
    <ol
      style="height: 50px; width: 52px"
      class="breadcrumb breadcrumb-custom overflow-hidden text-center border rounded-3 w-100 d-flex justify-content-center align-items-center"
    >
      @defer{ @if (estAlloue(this.selectedCountry)) {
      <span class="mx-1">
        <mat-icon [svgIcon]="getCountryCode(this.selectedCountry)"></mat-icon>
      </span>
      }@else{
      <mat-icon class="mx-1">language</mat-icon>
      } }
      <li>
        <span class="fw-bold">RUM > </span>
      </li>
      <li class="breadcrumb-item">
        <a
          class="fw-semibold text-decoration-none d-flex justify-content-center align-items-center"
          [routerLink]="getPages().overview"
        >
          <mat-icon class="mx-2">desktop_windows</mat-icon>
          <span>{{ SiteTitle }} Overview</span>
        </a>
      </li>
      <li class="breadcrumb-item">
        <a
          class="fw-semibold text-decoration-none d-flex justify-content-center align-items-center"
          [routerLink]="getPages().internal"
        >
          <span>Internal Pages</span>
        </a>
      </li>
      <li class="breadcrumb-item active"></li>
      <span
        matTooltip="{{ detailUrlDecoded }}"
        class="d-inline-block text-truncate"
        style="max-width: 150px"
      >
        {{ detailUrlDecoded }}
      </span>
      <mat-icon
        matTooltip="Copy Url"
        (click)="copyUrlToClipboard(detailUrlDecoded)"
        class="mx-1"
        style="cursor: pointer"
        >content_copy</mat-icon
      >
      @if(sharedMode) {
      <span>-</span>
      <span style="color: coral"> (Shared)</span>
      }
    </ol>
  </nav>
  @if (loading) {
  <div
    class="d-flex justify-content-center align-items-center my-3"
    style="height: -1000px"
  >
    <mat-spinner class="spinner" diameter="150"></mat-spinner>
  </div>
  }@else{
  <div class="d-flex justify-content-start align-items-center mx-4">
    <button
      class="btn btn-primary"
      [matMenuTriggerFor]="menu"
      #menuContacts="matMenuTrigger"
    >
      <div class="d-flex justify-content-center align-items-center w-100">
        <mat-icon>filter_list</mat-icon>
        <span>Filter</span>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
    </button>
    <mat-menu #menu="matMenu">
      <section mat-menu-item class="example-section">
        <mat-form-field
          appearance="outline"
          style="margin-bottom: -10px; margin-top: 10px"
        >
          <mat-label>Select Dimension</mat-label>
          <mat-select
            [value]="selectedDimension"
            (click)="$event.stopPropagation(); ($event.preventDefault)"
            (selectionChange)="closeMe(menuContacts)"
          >
            <mat-optgroup [label]="'Select Dimension'">
              <mat-option [value]="'all'" (click)="setDimension('all', 'all')">
                All
              </mat-option>
            </mat-optgroup>
            <mat-optgroup
              *ngFor="let group of dimensionGroups?.[device]"
              [label]="group.name"
            >
              <mat-option
                *ngFor="let dimension of group.dimensions"
                (click)="setDimension(dimension, group.id)"
                [value]="dimension"
              >
                <p *ngIf="dimension === selectedDimension">
                  {{ dimension }} ({{ group.id }})
                </p>
                <p *ngIf="dimension !== selectedDimension">
                  {{ dimension }}
                </p>
              </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
      </section>
      <div mat-menu-item>
        <mat-form-field
          appearance="outline"
          style="margin-bottom: -10px; margin-top: 10px"
        >
          <mat-label>Select Location</mat-label>
          <mat-select
            [value]="selectedCountry"
            (click)="$event.stopPropagation(); ($event.preventDefault)"
            (selectionChange)="closeMe(menuContacts)"
          >
            <mat-option [value]="'Global'" (click)="onCountrySelect('Global')"
              >Global</mat-option
            >
            @for(country of monitoringLocations; track country) {
            <mat-option [value]="country" (click)="onCountrySelect(country)">
              {{ country }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </mat-menu>
    <div style="display: flex; margin-left: 1%; gap: 10px; align-items: center; width: 100%;">
      <div style="font-weight: bold">Filters:</div>
      <div><mat-chip>Location: {{selectedCountry}}</mat-chip></div>
      <div>
        <mat-chip>
          @if(selectedDimGroup == 'all') {
            Dimension
          }
          @if(selectedDimGroup !== 'all') {
            {{ selectedDimGroup | slice:0:1 | uppercase }}{{ selectedDimGroup | slice:1 }}
          }
          : {{ selectedDimension | slice:0:1 | uppercase }}{{ selectedDimension | slice:1 }}
        </mat-chip></div>
        @if(selectedDimGroup !== 'all' || selectedCountry !== 'Global') {
           <mat-icon matTooltip="Reset filters" style="cursor: pointer;" (click)="clearCurrentFilters()">cancel</mat-icon>
        }
    </div>
  </div>

  <div class="m-4">
    <div class=" mt-3 d-flex justify-content-between align-items-center">
      <div class="radio-inputs mb-1">
        <label class="radio" *ngFor="let deviceT of deviceTypes">
          <input
            [value]="deviceT"
            type="radio"
            name="deviceRadio"
            [(ngModel)]="device"
            [checked]="device === deviceT"
            (change)="setDevice(deviceT)"
          />
          <span class="name"> {{ deviceT | uppercase }}</span>
        </label>
      </div>
      <div class="radio-inputs mb-1">
        <label class="radio">
          <input
            type="radio"
            name="periodRadio"
            value="monthly"
            [(ngModel)]="queryPeriod"
            (change)="setQueryPeriod($event)"
          />
          <span class="name">monthly</span>
        </label>
        <label class="radio">
          <input
            type="radio"
            name="periodRadio"
            value="weekly"
            [(ngModel)]="queryPeriod"
            (change)="setQueryPeriod($event)"
          />
          <span class="name">weekly</span>
        </label>
        <label class="radio">
          <input
            type="radio"
            name="periodRadio"
            value="daily"
            [(ngModel)]="queryPeriod"
            (change)="setQueryPeriod($event)"
          />
          <span class="name">daily</span>
        </label>
      </div>
    </div>
    @if (!metricsData) {
    <mat-card appearance="outline  " class="graph m-4">
      <mat-card-content>
        <div class="d-flex justify-content-center align-items-center">
          <h4>{{ apiResponseMessage }}</h4>
        </div></mat-card-content
      ></mat-card
    >
    } @if (!!metricsData) {
    <mat-tab-group mat-align-tabs="center" class="group mt-4">
      <mat-tab label="The last measurement" class="header-table">
        <div class="" style="overflow: hidden">
          <div class="row">
            @for (metric of metrics; track $index) {
            <div class="col-sm-12 col-md-6 col-lg-4">
              <mat-card class="graph m-3 p-2">
                <mat-card-content>
                  <div class="chart">
                    <strong>{{ metric.toUpperCase() }} </strong>
                  </div>
                  <div class="col-sm">
                    <ng-container *ngIf="!metricsData[metric]">
                      <div>
                        <h4 style="text-align: center">
                          No {{ metric.toUpperCase() }} data
                        </h4>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!!metricsData[metric]">
                      <div
                        class="d-flex flex-column justify-content-center align-items-center"
                      >
                        <span class="rounded-pill bg-light py-1 px-2">
                          75th :
                          {{ metricsData[metric].p75 | number : "1.2-2" }} ms
                        </span>
                        <apx-chart
                          [series]="metricsData[metric].series"
                          [chart]="metricsData[metric].chart"
                          [dataLabels]="metricsData[metric].dataLabels"
                          [plotOptions]="metricsData[metric].plotOptions"
                          [xaxis]="metricsData[metric].xaxis"
                          [yaxis]="metricsData[metric].yaxis"
                          [stroke]="metricsData[metric].stroke"
                          [fill]="metricsData[metric].fill"
                          [title]="metricsData[metric].title"
                          [tooltip]="metricsData[metric].tooltip"
                          [legend]="metricsData[metric].legend"
                        >
                        </apx-chart>
                      </div>
                    </ng-container>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            }
          </div>
        </div>
      </mat-tab>
      <mat-tab label="TTFB">
        <ng-template matTabContent>
          @if (!!attributionData.ttfb) {
          <mat-card class="graph m-4 p-2">
            <mat-card-content>
              <h3 style="text-align: center">
                <strong>TTFB attribution data breakdown</strong> ({{
                  attributionData.ttfb.total
                }}
                ms)
              </h3>
              <mat-divider></mat-divider>
              <mat-grid-list cols="4" rowHeight="8:1">
                <mat-grid-tile> Waiting time </mat-grid-tile>
                <mat-grid-tile>DNS timing</mat-grid-tile>
                <mat-grid-tile>Connection Time</mat-grid-tile>
                <mat-grid-tile>Request Time</mat-grid-tile>
                <mat-grid-tile>
                  {{ attributionData.ttfb.waitingTime }}ms,
                  {{
                    getPercentage(
                      attributionData.ttfb.waitingTime,
                      attributionData.ttfb.total
                    )
                  }}
                </mat-grid-tile>
                <mat-grid-tile>
                  {{ attributionData.ttfb.dnsTime }}ms,
                  {{
                    getPercentage(
                      attributionData.ttfb.dnsTime,
                      attributionData.ttfb.total
                    )
                  }}
                </mat-grid-tile>
                <mat-grid-tile>
                  {{ attributionData.ttfb.connectionTime }}ms,
                  {{
                    getPercentage(
                      attributionData.ttfb.connectionTime,
                      attributionData.ttfb.total
                    )
                  }}
                </mat-grid-tile>
                <mat-grid-tile>
                  {{ attributionData.ttfb.requestTime }}ms,
                  {{
                    getPercentage(
                      attributionData.ttfb.requestTime,
                      attributionData.ttfb.total
                    )
                  }}
                </mat-grid-tile>
              </mat-grid-list>
            </mat-card-content>
          </mat-card>
          } @if (queryPeriod === 'daily') {
          <div
            class="mx-4 mt-3 d-flex justify-content-between align-items-center"
          >
            <div></div>
            <div style="width: 15%">
              <div style="display: flex; align-items: end">
                <mat-select
                  [(ngModel)]="daysRangeSelected"
                  class="chart-select rounded"
                  (selectionChange)="executeSelectionChange()"
                >
                  <mat-option value="7">7 Days</mat-option>
                  <mat-option value="30">30 Days</mat-option>
                  <mat-option value="60">60 Days</mat-option>
                  <mat-option value="90">90 Days</mat-option>
                  <mat-option value="custom" (click)="openDaysSelectorDialog()"
                    >Custom range</mat-option
                  >
                </mat-select>
              </div>
            </div>
          </div>
          }
          <mat-card class="graph m-4 p-2">
            @if(metricsLoading) {
            <div
              class="d-flex justify-content-center align-items-center my-3"
              style="height: -1000px"
            >
              <mat-spinner class="spinner" diameter="150"></mat-spinner>
            </div>
            } @else {
            <mat-card-title class="visits-chart__header"> </mat-card-title>
            @if(queryPeriod === 'daily') {
            <div style="display: flex; margin-left: 8%; padding: 2%">
              <mat-chip-listbox aria-label="Days selection">
                <mat-chip-option
                  style="cursor: pointer"
                  *ngFor="let day of allDaysRange"
                  [selected]="selectedDatesRange === day"
                  (click)="setSelectedDatesRange(day)"
                >
                  {{ day }}
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
            }
            <mat-card-content>
              <div class="col-sm">
                <apx-chart
                  [series]="overTimeP75Data.ttfb.series"
                  [chart]="overTimeP75Data.ttfb.chart"
                  [xaxis]="overTimeP75Data.ttfb.xaxis"
                  [yaxis]="overTimeP75Data.ttfb.yaxis"
                  [dataLabels]="overTimeP75Data.ttfb.dataLabels"
                  [grid]="overTimeP75Data.ttfb.grid"
                  [stroke]="overTimeP75Data.ttfb.stroke"
                  [title]="overTimeP75Data.ttfb.title"
                  [grid]="overTimeP75Data.ttfb.grid"
                >
                </apx-chart>
              </div>
            </mat-card-content>
            }
          </mat-card>
          <mat-card class="graph m-4 p-2">
            @if(metricsLoading) {
            <div
              class="d-flex justify-content-center align-items-center my-3"
              style="height: -1000px"
            >
              <mat-spinner class="spinner" diameter="150"></mat-spinner>
            </div>
            } @else {
            <mat-card-title>TTFB over time</mat-card-title>
            @if(queryPeriod === 'daily') {
            <div style="display: flex; margin-left: 8%; padding: 2%">
              <mat-chip-listbox aria-label="Days selection">
                <mat-chip-option
                  style="cursor: pointer"
                  *ngFor="let day of allDaysRange"
                  [selected]="selectedDatesRange === day"
                  (click)="setSelectedDatesRange(day)"
                >
                  {{ day }}
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
            }
            <mat-card-content>
              <apx-chart
                [series]="overTimeBarchartData.ttfb.series"
                [chart]="overTimeBarchartData.ttfb.chart"
                [dataLabels]="overTimeBarchartData.ttfb.dataLabels"
                [plotOptions]="overTimeBarchartData.ttfb.plotOptions"
                [responsive]="overTimeBarchartData.ttfb.responsive"
                [xaxis]="overTimeBarchartData.ttfb.xaxis"
                [yaxis]="overTimeBarchartData.ttfb.yaxis"
                [legend]="overTimeBarchartData.ttfb.legend"
                [fill]="overTimeBarchartData.ttfb.fill"
                [grid]="overTimeBarchartData.ttfb.grid"
                >></apx-chart
              > </mat-card-content
            >}
          </mat-card>
        </ng-template>
      </mat-tab>
      <mat-tab label="FCP">
        <ng-template matTabContent>
          @if (queryPeriod === 'daily') {
          <div
            class="mx-4 mt-3 d-flex justify-content-between align-items-center"
          >
            <div></div>
            <div style="width: 15%">
              <div style="display: flex; align-items: end">
                <mat-select
                  [(ngModel)]="daysRangeSelected"
                  class="chart-select rounded"
                  (selectionChange)="executeSelectionChange()"
                >
                  <mat-option value="7">7 Days</mat-option>
                  <mat-option value="30">30 Days</mat-option>
                  <mat-option value="60">60 Days</mat-option>
                  <mat-option value="90">90 Days</mat-option>
                  <mat-option value="custom" (click)="openDaysSelectorDialog()"
                    >Custom range</mat-option
                  >
                </mat-select>
              </div>
            </div>
          </div>
          } @if (!!attributionData.fcp) {
          <mat-card class="graph m-4 p-2">
            @if(metricsLoading) {
            <div
              class="d-flex justify-content-center align-items-center my-3"
              style="height: -1000px"
            >
              <mat-spinner class="spinner" diameter="150"></mat-spinner>
            </div>
            } @else {
            <mat-card-content>
              <h3 style="text-align: center">
                <strong>FCP attribution data breakdown</strong>
              </h3>
              <mat-divider></mat-divider>
              <mat-grid-list cols="2" rowHeight="16:1">
                <mat-grid-tile>Time to first Byte (TTFB)</mat-grid-tile>
                <mat-grid-tile>First byte to FCP</mat-grid-tile>
                <mat-grid-tile>
                  {{ attributionData.fcp.timeToFirstByte }}ms
                </mat-grid-tile>
                <mat-grid-tile>
                  {{ attributionData.fcp.firstByteToFcp }}
                </mat-grid-tile>
              </mat-grid-list> </mat-card-content
            >}
          </mat-card>
          }
          <mat-card class="graph m-4 p-2">
            @if(metricsLoading) {
            <div
              class="d-flex justify-content-center align-items-center my-3"
              style="height: -1000px"
            >
              <mat-spinner class="spinner" diameter="150"></mat-spinner>
            </div>
            } @else {
            <mat-card-title class="visits-chart__header"> </mat-card-title>
            <mat-card-content>
              @if(queryPeriod === 'daily') {
              <div style="display: flex; margin-left: 8%; padding: 2%">
                <mat-chip-listbox aria-label="Days selection">
                  <mat-chip-option
                    style="cursor: pointer"
                    *ngFor="let day of allDaysRange"
                    [selected]="selectedDatesRange === day"
                    (click)="setSelectedDatesRange(day)"
                  >
                    {{ day }}
                  </mat-chip-option>
                </mat-chip-listbox>
              </div>
              }
              <div class="col-sm">
                <apx-chart
                  [series]="overTimeP75Data.fcp.series"
                  [chart]="overTimeP75Data.fcp.chart"
                  [xaxis]="overTimeP75Data.fcp.xaxis"
                  [yaxis]="overTimeP75Data.fcp.yaxis"
                  [dataLabels]="overTimeP75Data.fcp.dataLabels"
                  [grid]="overTimeP75Data.fcp.grid"
                  [stroke]="overTimeP75Data.fcp.stroke"
                  [title]="overTimeP75Data.fcp.title"
                  [grid]="overTimeP75Data.fcp.grid"
                >
                </apx-chart>
              </div> </mat-card-content
            >}
          </mat-card>
          <mat-card class="graph m-4 p-2">
            @if(metricsLoading) {
            <div
              class="d-flex justify-content-center align-items-center my-3"
              style="height: -1000px"
            >
              <mat-spinner class="spinner" diameter="150"></mat-spinner>
            </div>
            } @else {
            <mat-card-title>FCP over time</mat-card-title>
            <mat-card-content>
              @if(queryPeriod === 'daily') {
              <div style="display: flex; margin-left: 8%; padding: 2%">
                <mat-chip-listbox aria-label="Days selection">
                  <mat-chip-option
                    style="cursor: pointer"
                    *ngFor="let day of allDaysRange"
                    [selected]="selectedDatesRange === day"
                    (click)="setSelectedDatesRange(day)"
                  >
                    {{ day }}
                  </mat-chip-option>
                </mat-chip-listbox>
              </div>
              }
              <apx-chart
                [series]="overTimeBarchartData.fcp.series"
                [chart]="overTimeBarchartData.fcp.chart"
                [dataLabels]="overTimeBarchartData.fcp.dataLabels"
                [plotOptions]="overTimeBarchartData.fcp.plotOptions"
                [responsive]="overTimeBarchartData.fcp.responsive"
                [xaxis]="overTimeBarchartData.fcp.xaxis"
                [yaxis]="overTimeBarchartData.fcp.yaxis"
                [legend]="overTimeBarchartData.fcp.legend"
                [fill]="overTimeBarchartData.fcp.fill"
                [grid]="overTimeBarchartData.fcp.grid"
                >></apx-chart
              > </mat-card-content
            >}
          </mat-card>
        </ng-template>
      </mat-tab>
      <mat-tab label="LCP">
        <ng-template matTabContent>
          <div
            class="mx-4 mt-3 d-flex justify-content-between align-items-center"
          >
            <div class="btn btn-sm btn-primary rounded-pill mx-4 mt-4">
              <mat-slide-toggle
                color="primary"
                style="color: white"
                [(ngModel)]="showDebugLcp"
              >
                <span class="text-white space">Show Debug data</span>
              </mat-slide-toggle>
            </div>
            @if (queryPeriod === 'daily') {

            <div></div>
            <div style="width: 15%">
              <div style="display: flex; align-items: end">
                <mat-select
                  [(ngModel)]="daysRangeSelected"
                  class="chart-select rounded"
                  (selectionChange)="executeSelectionChange()"
                >
                  <mat-option value="7">7 Days</mat-option>
                  <mat-option value="30">30 Days</mat-option>
                  <mat-option value="60">60 Days</mat-option>
                  <mat-option value="90">90 Days</mat-option>
                  <mat-option value="custom" (click)="openDaysSelectorDialog()"
                    >Custom range</mat-option
                  >
                </mat-select>
              </div>
            </div>
            }
          </div>
          @if (showDebugLcp) {
          <mat-card class="graph m-4 p-2">
            <mat-card-content>
              <h3 style="text-align: center">
                <strong
                  >LCP attribution Debug data({{dataList?.[0]?.pageViews}} total
                  page views)</strong
                >
              </h3>
              <mat-divider></mat-divider>
              <mat-table
                matSort
                [dataSource]="lcpAttributionDatasource"
                (matSortChange)="onMatSortChange($event)"
              >
                <ng-container matColumnDef="lcpElement">
                  <mat-header-cell *matHeaderCellDef>
                    <b>LCP Element</b>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let entry">
                    {{ entry?.targetElement || "-" }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="resourceURL">
                  <mat-header-cell *matHeaderCellDef>
                    <b>Resource URL</b>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let entry">
                    {{ entry?.resouceUrl || "-" }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="p75thValue">
                  <mat-header-cell *matHeaderCellDef>
                    <b>p75th value</b>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let entry">
                    {{ formatToFixed(entry?.p75?.[0]) }}ms
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="pvEvents">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <b>Page Visit Events</b>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let entry">
                    {{ entry?.count }}
                  </mat-cell>
                </ng-container>
                <mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns">
                </mat-row>
              </mat-table>
            </mat-card-content>
          </mat-card>
          } @if(!showDebugLcp){
          <mat-card class="graph m-4 p-2">
            @if(metricsLoading) {
            <div
              class="d-flex justify-content-center align-items-center my-3"
              style="height: -1000px"
            >
              <mat-spinner class="spinner" diameter="150"></mat-spinner>
            </div>
            } @else {
            <mat-card-title class="visits-chart__header"> </mat-card-title>
            <mat-card-content>
              @if(queryPeriod === 'daily') {
              <div style="display: flex; margin-left: 8%; padding: 2%">
                <mat-chip-listbox aria-label="Days selection">
                  <mat-chip-option
                    style="cursor: pointer"
                    *ngFor="let day of allDaysRange"
                    [selected]="selectedDatesRange === day"
                    (click)="setSelectedDatesRange(day)"
                  >
                    {{ day }}
                  </mat-chip-option>
                </mat-chip-listbox>
              </div>
              }
              <div class="col-sm">
                <apx-chart
                  [series]="overTimeP75Data.lcp.series"
                  [chart]="overTimeP75Data.lcp.chart"
                  [xaxis]="overTimeP75Data.lcp.xaxis"
                  [yaxis]="overTimeP75Data.lcp.yaxis"
                  [dataLabels]="overTimeP75Data.lcp.dataLabels"
                  [grid]="overTimeP75Data.lcp.grid"
                  [stroke]="overTimeP75Data.lcp.stroke"
                  [title]="overTimeP75Data.lcp.title"
                  [grid]="overTimeP75Data.lcp.grid"
                >
                </apx-chart>
              </div> </mat-card-content
            >}
          </mat-card>
          <mat-card class="graph m-4 p-2">
            @if(metricsLoading) {
            <div
              class="d-flex justify-content-center align-items-center my-3"
              style="height: -1000px"
            >
              <mat-spinner class="spinner" diameter="150"></mat-spinner>
            </div>
            } @else {
            <mat-card-title>LCP over time</mat-card-title>
            <mat-card-content>
              @if(queryPeriod === 'daily') {
              <div style="display: flex; margin-left: 8%; padding: 2%">
                <mat-chip-listbox aria-label="Days selection">
                  <mat-chip-option
                    style="cursor: pointer"
                    *ngFor="let day of allDaysRange"
                    [selected]="selectedDatesRange === day"
                    (click)="setSelectedDatesRange(day)"
                  >
                    {{ day }}
                  </mat-chip-option>
                </mat-chip-listbox>
              </div>
              }
              <apx-chart
                [series]="overTimeBarchartData.lcp.series"
                [chart]="overTimeBarchartData.lcp.chart"
                [dataLabels]="overTimeBarchartData.lcp.dataLabels"
                [plotOptions]="overTimeBarchartData.lcp.plotOptions"
                [responsive]="overTimeBarchartData.lcp.responsive"
                [xaxis]="overTimeBarchartData.lcp.xaxis"
                [yaxis]="overTimeBarchartData.lcp.yaxis"
                [legend]="overTimeBarchartData.lcp.legend"
                [fill]="overTimeBarchartData.lcp.fill"
                [grid]="overTimeBarchartData.lcp.grid"
                >></apx-chart
              > </mat-card-content
            >}
          </mat-card>
          }
        </ng-template>
      </mat-tab>
      <mat-tab label="CLS">
        <ng-template matTabContent>
          <div
            class="mx-4 mt-3 d-flex justify-content-between align-items-center"
          >
            <div class="btn btn-sm btn-primary rounded-pill mx-4 mt-4">
              <mat-slide-toggle
                color="primary"
                style="color: white"
                [(ngModel)]="showDebugCLS"
              >
                <span class="text-white space">Show Debug data</span>
              </mat-slide-toggle>
            </div>
            @if (queryPeriod === 'daily') {

            <div style="width: 15%">
              <div style="display: flex; align-items: end">
                <mat-select
                  [(ngModel)]="daysRangeSelected"
                  class="chart-select rounded"
                  (selectionChange)="executeSelectionChange()"
                >
                  <mat-option value="7">7 Days</mat-option>
                  <mat-option value="30">30 Days</mat-option>
                  <mat-option value="60">60 Days</mat-option>
                  <mat-option value="90">90 Days</mat-option>
                  <mat-option value="custom" (click)="openDaysSelectorDialog()"
                    >Custom range</mat-option
                  >
                </mat-select>
              </div>
            </div>

            }
          </div>
          @if (showDebugCLS) {
          <mat-card class="graph m-4 p-2">
            <mat-card-body
              ><h3 style="text-align: center">
                <strong
                  >CLS attribution data({{dataList?.[0]?.pageViews}} total page
                  views)</strong
                >
              </h3>
              <mat-divider></mat-divider>
              <mat-grid-list cols="3" rowHeight="9:1">
                <mat-grid-tile> Largest shift target Element </mat-grid-tile>
                <mat-grid-tile>Largest shift value</mat-grid-tile>
                <mat-grid-tile>Largest shift time</mat-grid-tile>
                <mat-grid-tile>
                  <p style="color: dodgerblue">
                    {{ attributionData?.[device]?.cls?.[0]?.targetElement || "-" }}
                  </p>
                </mat-grid-tile>
                <mat-grid-tile>
                  <p>
                    {{ formatToFixed(attributionData?.[device]?.cls?.[0]?.largestShiftValue, 2) || "-" }}
                  </p>
                </mat-grid-tile>
                <mat-grid-tile>
                  <p>
                    {{ formatToFixed(attributionData?.[device]?.cls?.[0]?.largestShiftTime, 2) || "-"










                    }}ms
                  </p>
                </mat-grid-tile>
              </mat-grid-list></mat-card-body
            >
          </mat-card>
          } @if(!showDebugCLS) {
          <mat-card class="graph m-4 p-2">
            @if(metricsLoading) {
            <div
              class="d-flex justify-content-center align-items-center my-3"
              style="height: -1000px"
            >
              <mat-spinner class="spinner" diameter="150"></mat-spinner>
            </div>
            } @else {
            <mat-card-title class="visits-chart__header"> </mat-card-title>
            <mat-card-content>
              @if(queryPeriod === 'daily') {
              <div style="display: flex; margin-left: 8%; padding: 2%">
                <mat-chip-listbox aria-label="Days selection">
                  <mat-chip-option
                    style="cursor: pointer"
                    *ngFor="let day of allDaysRange"
                    [selected]="selectedDatesRange === day"
                    (click)="setSelectedDatesRange(day)"
                  >
                    {{ day }}
                  </mat-chip-option>
                </mat-chip-listbox>
              </div>
              }
              <div class="col-sm">
                <apx-chart
                  [series]="overTimeP75Data.cls.series"
                  [chart]="overTimeP75Data.cls.chart"
                  [xaxis]="overTimeP75Data.cls.xaxis"
                  [yaxis]="overTimeP75Data.cls.yaxis"
                  [dataLabels]="overTimeP75Data.cls.dataLabels"
                  [grid]="overTimeP75Data.cls.grid"
                  [stroke]="overTimeP75Data.cls.stroke"
                  [title]="overTimeP75Data.cls.title"
                  [grid]="overTimeP75Data.cls.grid"
                >
                </apx-chart>
              </div> </mat-card-content
            >}
          </mat-card>
          <mat-card class="graph m-4 p-2">
            @if(metricsLoading) {
            <div
              class="d-flex justify-content-center align-items-center my-3"
              style="height: -1000px"
            >
              <mat-spinner class="spinner" diameter="150"></mat-spinner>
            </div>
            } @else {
            <mat-card-title>CLS over time</mat-card-title>
            <mat-card-content>
              @if(queryPeriod === 'daily') {
              <div style="display: flex; margin-left: 8%; padding: 2%">
                <mat-chip-listbox aria-label="Days selection">
                  <mat-chip-option
                    style="cursor: pointer"
                    *ngFor="let day of allDaysRange"
                    [selected]="selectedDatesRange === day"
                    (click)="setSelectedDatesRange(day)"
                  >
                    {{ day }}
                  </mat-chip-option>
                </mat-chip-listbox>
              </div>
              }
              <apx-chart
                [series]="overTimeBarchartData.cls.series"
                [chart]="overTimeBarchartData.cls.chart"
                [dataLabels]="overTimeBarchartData.cls.dataLabels"
                [plotOptions]="overTimeBarchartData.cls.plotOptions"
                [responsive]="overTimeBarchartData.cls.responsive"
                [xaxis]="overTimeBarchartData.cls.xaxis"
                [yaxis]="overTimeBarchartData.cls.yaxis"
                [legend]="overTimeBarchartData.cls.legend"
                [fill]="overTimeBarchartData.cls.fill"
                [grid]="overTimeBarchartData.cls.grid"
                >></apx-chart
              > </mat-card-content
            >}
          </mat-card>
          }
        </ng-template>
      </mat-tab>
      <mat-tab label="INP">
        <ng-template matTabContent>
          <div
            class="mx-4 mt-3 d-flex justify-content-between align-items-center"
          >
            <div class="btn btn-sm btn-primary rounded-pill mx-4 mt-4">
              <mat-slide-toggle
                color="primary"
                style="color: white"
                [(ngModel)]="showDebugINP"
              >
                <span class="text-white space">Show Debug data</span>
              </mat-slide-toggle>
            </div>
            @if (queryPeriod === 'daily') {

            <div style="width: 15%">
              <div style="display: flex; align-items: end">
                <mat-select
                  [(ngModel)]="daysRangeSelected"
                  class="chart-select rounded"
                  (selectionChange)="executeSelectionChange()"
                >
                  <mat-option value="7">7 Days</mat-option>
                  <mat-option value="30">30 Days</mat-option>
                  <mat-option value="60">60 Days</mat-option>
                  <mat-option value="90">90 Days</mat-option>
                  <mat-option value="custom" (click)="openDaysSelectorDialog()"
                    >Custom range</mat-option
                  >
                </mat-select>
              </div>
            </div>

            }
          </div>
          @if (showDebugINP) {
          <mat-card class="graph m-4 p-2">
            <mat-card-body
              ><h3 style="text-align: center">
                <strong>INP attribution data breakdown</strong>
              </h3>
              <mat-divider></mat-divider>
              <mat-grid-list cols="3" rowHeight="9:1">
                <mat-grid-tile> Event target element </mat-grid-tile>
                <mat-grid-tile>Event type</mat-grid-tile>
                <mat-grid-tile>Largest event time</mat-grid-tile>
                <mat-grid-tile>
                  {{attributionData?.[device]?.inp?.[0]?.targetElement || "-"}}
                </mat-grid-tile>
                <mat-grid-tile>
                  {{ attributionData?.[device]?.inp?.[0]?.inpEventType || "-"}}
                </mat-grid-tile>
                <mat-grid-tile>
                  {{ attributionData?.[device]?.inp?.[0]?.value || "-"}}
                </mat-grid-tile>
              </mat-grid-list></mat-card-body
            >
          </mat-card>
          } @if(!showDebugINP) {
          <mat-card class="graph m-4 p-2">
            @if(metricsLoading) {
            <div
              class="d-flex justify-content-center align-items-center my-3"
              style="height: -1000px"
            >
              <mat-spinner class="spinner" diameter="150"></mat-spinner>
            </div>
            } @else {
            <mat-card-title class="visits-chart__header"> </mat-card-title>
            <mat-card-content>
              @if(queryPeriod === 'daily') {
              <div style="display: flex; margin-left: 8%; padding: 2%">
                <mat-chip-listbox aria-label="Days selection">
                  <mat-chip-option
                    style="cursor: pointer"
                    *ngFor="let day of allDaysRange"
                    [selected]="selectedDatesRange === day"
                    (click)="setSelectedDatesRange(day)"
                  >
                    {{ day }}
                  </mat-chip-option>
                </mat-chip-listbox>
              </div>
              }
              <div class="col-sm">
                <apx-chart
                  [series]="overTimeP75Data.inp.series"
                  [chart]="overTimeP75Data.inp.chart"
                  [xaxis]="overTimeP75Data.inp.xaxis"
                  [yaxis]="overTimeP75Data.inp.yaxis"
                  [dataLabels]="overTimeP75Data.inp.dataLabels"
                  [grid]="overTimeP75Data.inp.grid"
                  [stroke]="overTimeP75Data.inp.stroke"
                  [title]="overTimeP75Data.inp.title"
                  [grid]="overTimeP75Data.inp.grid"
                >
                </apx-chart>
              </div> </mat-card-content
            >}
          </mat-card>
          <mat-card class="graph m-4 p-2">
            @if(metricsLoading) {
            <div
              class="d-flex justify-content-center align-items-center my-3"
              style="height: -1000px"
            >
              <mat-spinner class="spinner" diameter="150"></mat-spinner>
            </div>
            } @else {
            <mat-card-title>INP over time</mat-card-title>
            <mat-card-content>
              @if(queryPeriod === 'daily') {
              <div style="display: flex; margin-left: 8%; padding: 2%">
                <mat-chip-listbox aria-label="Days selection">
                  <mat-chip-option
                    style="cursor: pointer"
                    *ngFor="let day of allDaysRange"
                    [selected]="selectedDatesRange === day"
                    (click)="setSelectedDatesRange(day)"
                  >
                    {{ day }}
                  </mat-chip-option>
                </mat-chip-listbox>
              </div>
              }
              <apx-chart
                [series]="overTimeBarchartData.inp.series"
                [chart]="overTimeBarchartData.inp.chart"
                [dataLabels]="overTimeBarchartData.inp.dataLabels"
                [plotOptions]="overTimeBarchartData.inp.plotOptions"
                [responsive]="overTimeBarchartData.inp.responsive"
                [xaxis]="overTimeBarchartData.inp.xaxis"
                [yaxis]="overTimeBarchartData.inp.yaxis"
                [legend]="overTimeBarchartData.inp.legend"
                [fill]="overTimeBarchartData.inp.fill"
                [grid]="overTimeBarchartData.inp.grid"
                >></apx-chart
              > </mat-card-content
            >}
          </mat-card>
          }
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    }
  </div>
  }
</div>
