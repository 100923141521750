@if (free) {
  <div class="d-flex justify-content-center align-items-center">
    <div class="notifications-container">
      <div class="alert">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 alert-svg">
              <path clip-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" fill-rule="evenodd"></path>
            </svg>
          </div>
          <div class="alert-prompt-wrap">
            <p class="text-sm text-yellow-700 m-0">
              Fake data displayed. To get metric histograms you should upgrade your account
              <a class="alert-prompt-link" [routerLink]="'/buyPlan'">Upgrade now!</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
}
@if(isUXRovertime){
  <div class="d-flex justify-content-center align-items-center">
    <div class="notifications-container">
      <div class="alert">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 alert-svg">
              <path clip-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" fill-rule="evenodd"></path>
            </svg>
          </div>
          <div class="alert-prompt-wrap">
            <p class="text-sm text-yellow-700">
              INP replaces FID on March 12, 2024! Check out the new and improved UXR score here on
              <a class="alert-prompt-link"  href="https://speetals.com/user-experience-rating-uxr/"  target="_blank">Speetals.com.</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
}
<mat-dialog-content>
  <div class="row" style="padding: 2%">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [stroke]="chartOptions.stroke"
      [tooltip]="chartOptions.tooltip"
      [dataLabels]="chartOptions.dataLabels"
      [legend]="chartOptions.legend"
      [markers]="chartOptions.markers"
      [grid]="chartOptions.grid"
      [yaxis]="chartOptions.yaxis"
      [annotations]="chartOptions.annotations"
      [title]="chartOptions.title"
    ></apx-chart>
    @if (isUXRovertime) {
      <div style="text-align: center;">
        <b style="font-size: 6em; color: #15cd6a">-</b> Good
        <b style="font-size: 6em; color: #fba400">-</b> Needs Improvement
        <b style="font-size: 6em; color: #fa4f44;">-</b> Poor
      </div>
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button  type="button" class="btn btn-primary" mat-dialog-close tabindex="-1">
    {{ cancelButtonText }}
  </button>
</mat-dialog-actions>