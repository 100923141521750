<app-layout>
  <!-- Toogle Bouton -->
  @if (!!currentPlan) {
    <mat-card style="margin-top: 1%; margin-bottom: 1%; text-align: center">
      <mat-card-title style="color: orange;">Qouta reached! Please upgrade your plan</mat-card-title>
      <mat-card-content><h2>Your current plan is {{currentPlan.name}}</h2></mat-card-content>
    </mat-card>
  }
<div class="d-flex justify-content-center align-items-center">
  <mat-card appearance="outlined" class="card m-4 shadow w-75">
    <section class="text-center">
      <div class="billing-container d-flex justify-content-center align-items-center">
        <label for="toggle" class="label">Monthly Billing</label>
        <div class="toggle-switch">
          <input type="checkbox" id="toggle" class="checkbox" (change)="changeBillingType($event)" checked/>
          <label for="toggle" class="switch"></label>
        </div>
        <label for="toggle" class="label annual">Annual Billing <span class="discount">Get 3 months free! (25% savings)</span></label>
      </div>      
    </section>

    
    <div class="row m-4 justify-content-center">
      @for(plan of plans; track $index){

        <div class="col-sm-12 col-md-3">

          <div class=" graph  shadow hover-zoom">
            
            <div class="mx-3 card-body">
              @if(plan.featured){
                <div class="container__wrapper">
                  <div class="ribbon">  Most Popular!</div>
                </div> 
              }
              <h4 class=" my-2 ">{{plan.name}}</h4>
              <!-- <p class="text-muted mb-2">
                All the essentials for starting a business
              </p> -->
              @if(plan.name !== 'Trial'){
                  @if ((typeMA =="yearly")) {
                    <!-- <span class="pricing-option" [ngClass]="{'free': price[$index] === 0 }">${{ roundPrice(price[$index] / 12) }}/mo <br></span> -->
                    <div class="d-flex justify-content-start align-items-center">
                      <div class="h4 fw-bold" [ngClass]="{'free': price[$index] === 0 }" >
                        <span>${{ roundPrice(price[$index] / 12) }}</span>
                        <small class="text-muted" style="font-size: 18px;">/mo</small>
                      </div>
                      <div class="h4 fw-normal flex-shrink-1"style="margin-left: 10px;" >
                        <span style="font-size: 18px;">${{price[$index] }}</span>
                        <small class="text-muted" style="font-size: 15px;">/year</small>
                      </div>
                    </div>
                    <button class="btn btn-primary d-block mb-2 mt-3 text-capitalize" (click) ="redirectToCheckout(plan.priceID.yearly, 'subscription')">Subscribe</button>
                  
                  }
                  @if ((typeMA !=="yearly")) {
                    <p class="h4 fw-bold">${{ price[$index] }}<small class="text-muted" style="font-size: 18px;">/mo</small></p>
                    <button class="btn btn-primary d-block mb-2 mt-3 text-capitalize" (click) ="redirectToCheckout(plan.priceID.monthly, 'subscription')">Subscribe</button>
                  }

              }@else{
                <p class="h4 fw-bold">${{ price[$index] }} <small class="text-muted" style="font-size: 18px;">10 days trial</small></p>
                <button class="btn btn-primary d-block mb-2 mt-3 text-capitalize" (click) ="redirectToCheckout(plan.priceID.monthly, 'payment')">Subscribe</button>
              }
              
            </div>
            <div class="card-footer">
              <ol class="list-unstyled mb-0 px-2">
                <p class="my-3 fw-bold text-muted text-center">
                </p>
                    <li class="mb-3"*ngFor="let detail of plan.details" >
                      @if (!isValueBoolean(detail.value)) {
                        <div class="d-flex justify-content-start align-items-center">
                          <span>
                            {{ detail.text }}
                            <span class="ptable-title"> <i class="pe" #tooltip="matTooltip"
                              [matTooltip]=detail.tooltip> <img alt="Hover to show help text"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAAXNSR0IArs4c6QAAA3pJREFUOBF1VF1oXFUQnjn3bkNpq22aLXs3VoRqUcGH5KEWxGStiFh/UGqK1mIRH2oppFlQaV4kvmhBYfPT+gdSUYti6w+lVSKoNyli86KC0odqX6S5d5OtSaDREHfPGb+5zV2vqR64zJxvZr6ZM2fuYfqPdd+wtPxh4/uZ5UEnfBOR5JlphoQvkKHPje+fDPdvmF8eysuBrqFolwi9TCLXL7c198w1QzwQHii8zsiY4k2yno/Em4riwyLyDMEBhhPCcszzcxN0a/7Syp+q6+addBqSx0R4t5D4RHyi0B48eXwnLyghgCtrKqoOKhGOc9EY0xP2Fs6qZUDEjB+JN25sCaK39vIooNG7h+NX6o4+hv+j1ckYh5CdWmFSWfdQ9Ihz8gkxRbmWli1f72ub1Erh+DyC+9GzNZB/IeDo2jW5504+nb9cqsyudbzwHYhuNmz2j/UFrxkNAtEhOJMn5gklUn1qMj4IkpdQKTj4G3yzCNw7d7n+ttrD8rq5nOEdwK0jGSgdmV5tpuNqF2ybAY6G5SBUR00A0Q9s3uT8jvG+4jbIG0H8Cwh77nyzFqjfV73BOSZ5D+fMu0bjISPObVcDmvqhSl216kyABq+C+iVG4FfFdBSEeEJ186e7QaUuR0txjrbjhnmTgr5nflCpK+xdf5HZ7PGNpz1LVvdQfBcy4li0eO015ucUX234+0Rn2eRjplp1Yz1/JnVQOd4XvJvuuyuTj4sT3eP2zR69gNS2cFthln6MkIdaDcBaYrC2LXXIyntGakVH9A6xWDjvyiZJ/M7VluKSQabzCoo0bs+SpHrd2k7oKzCgh8fK7R+keCpto34ljuW8MeKdSgyOdqcOWemvXHEGt3rI92kkizd1x0kcRvaUCfs2nEVT0Xy5o1SJHm46LSlusd6GsreIpavaUBqubsWg78Al/rbKC04bZBXyvGf1f3QsR7sq0S1Zwjq59QjobDAXsnhp+PfrnLPHkQgU1P9FLy82f/TuwfgFJ+5FGOaM8FNhufhZGoxBRcA/r0OpEpcsu2MkVAT+BoZ6n/o2yXSTEOLX0GwwfYu3631mfwKVX/Jso7VhXQc48WrIveqPMRkptBfKeDWs7v5FpkDXSHUbWfsq5qZD9/+zcHN8cOxA8dOs/SoyNeqxSoPTWx3bB3CUzYCWXlq5YMiczrcXzqTVZMn+Bk2UeJEHhK1tAAAAAElFTkSuQmCC"
                            style="width: 15px;"></i></span>
                            : 
                          </span>
                          @if(detail.value !== 'Unlimited' ){
                            <b class="mx-1">{{detail.value}}</b>
                          }@else{
                              <mat-icon>all_inclusive</mat-icon>
                          }
                        </div>

                      }
                      @if (isValueBoolean(detail.value) && detail.value) {
                        <div class="d-flex justify-content-start align-items-center">
                          <span>{{ detail.text }}
                            <span class="ptable-title"> <i class="pe" #tooltip="matTooltip"
                              [matTooltip]=detail.tooltip> <img alt="Hover to show help text"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAAXNSR0IArs4c6QAAA3pJREFUOBF1VF1oXFUQnjn3bkNpq22aLXs3VoRqUcGH5KEWxGStiFh/UGqK1mIRH2oppFlQaV4kvmhBYfPT+gdSUYti6w+lVSKoNyli86KC0odqX6S5d5OtSaDREHfPGb+5zV2vqR64zJxvZr6ZM2fuYfqPdd+wtPxh4/uZ5UEnfBOR5JlphoQvkKHPje+fDPdvmF8eysuBrqFolwi9TCLXL7c198w1QzwQHii8zsiY4k2yno/Em4riwyLyDMEBhhPCcszzcxN0a/7Syp+q6+addBqSx0R4t5D4RHyi0B48eXwnLyghgCtrKqoOKhGOc9EY0xP2Fs6qZUDEjB+JN25sCaK39vIooNG7h+NX6o4+hv+j1ckYh5CdWmFSWfdQ9Ihz8gkxRbmWli1f72ub1Erh+DyC+9GzNZB/IeDo2jW5504+nb9cqsyudbzwHYhuNmz2j/UFrxkNAtEhOJMn5gklUn1qMj4IkpdQKTj4G3yzCNw7d7n+ttrD8rq5nOEdwK0jGSgdmV5tpuNqF2ybAY6G5SBUR00A0Q9s3uT8jvG+4jbIG0H8Cwh77nyzFqjfV73BOSZ5D+fMu0bjISPObVcDmvqhSl216kyABq+C+iVG4FfFdBSEeEJ186e7QaUuR0txjrbjhnmTgr5nflCpK+xdf5HZ7PGNpz1LVvdQfBcy4li0eO015ucUX234+0Rn2eRjplp1Yz1/JnVQOd4XvJvuuyuTj4sT3eP2zR69gNS2cFthln6MkIdaDcBaYrC2LXXIyntGakVH9A6xWDjvyiZJ/M7VluKSQabzCoo0bs+SpHrd2k7oKzCgh8fK7R+keCpto34ljuW8MeKdSgyOdqcOWemvXHEGt3rI92kkizd1x0kcRvaUCfs2nEVT0Xy5o1SJHm46LSlusd6GsreIpavaUBqubsWg78Al/rbKC04bZBXyvGf1f3QsR7sq0S1Zwjq59QjobDAXsnhp+PfrnLPHkQgU1P9FLy82f/TuwfgFJ+5FGOaM8FNhufhZGoxBRcA/r0OpEpcsu2MkVAT+BoZ6n/o2yXSTEOLX0GwwfYu3631mfwKVX/Jso7VhXQc48WrIveqPMRkptBfKeDWs7v5FpkDXSHUbWfsq5qZD9/+zcHN8cOxA8dOs/SoyNeqxSoPTWx3bB3CUzYCWXlq5YMiczrcXzqTVZMn+Bk2UeJEHhK1tAAAAAElFTkSuQmCC"
                            style="width: 15px;"></i>
                          </span>
                            :
                          </span>
                          <span style="color: green;">
                            <mat-icon>done</mat-icon>
                          </span>
                        </div>
                      }
                      @if (isValueBoolean(detail.value) && !detail.value) {
                        <div class="d-flex justify-content-start align-items-center">
                          <span>{{ detail.text }}<span class="ptable-title"> <i class="pe" #tooltip="matTooltip"
                            [matTooltip]=detail.tooltip> <img alt="Hover to show help text"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAAXNSR0IArs4c6QAAA3pJREFUOBF1VF1oXFUQnjn3bkNpq22aLXs3VoRqUcGH5KEWxGStiFh/UGqK1mIRH2oppFlQaV4kvmhBYfPT+gdSUYti6w+lVSKoNyli86KC0odqX6S5d5OtSaDREHfPGb+5zV2vqR64zJxvZr6ZM2fuYfqPdd+wtPxh4/uZ5UEnfBOR5JlphoQvkKHPje+fDPdvmF8eysuBrqFolwi9TCLXL7c198w1QzwQHii8zsiY4k2yno/Em4riwyLyDMEBhhPCcszzcxN0a/7Syp+q6+addBqSx0R4t5D4RHyi0B48eXwnLyghgCtrKqoOKhGOc9EY0xP2Fs6qZUDEjB+JN25sCaK39vIooNG7h+NX6o4+hv+j1ckYh5CdWmFSWfdQ9Ihz8gkxRbmWli1f72ub1Erh+DyC+9GzNZB/IeDo2jW5504+nb9cqsyudbzwHYhuNmz2j/UFrxkNAtEhOJMn5gklUn1qMj4IkpdQKTj4G3yzCNw7d7n+ttrD8rq5nOEdwK0jGSgdmV5tpuNqF2ybAY6G5SBUR00A0Q9s3uT8jvG+4jbIG0H8Cwh77nyzFqjfV73BOSZ5D+fMu0bjISPObVcDmvqhSl216kyABq+C+iVG4FfFdBSEeEJ186e7QaUuR0txjrbjhnmTgr5nflCpK+xdf5HZ7PGNpz1LVvdQfBcy4li0eO015ucUX234+0Rn2eRjplp1Yz1/JnVQOd4XvJvuuyuTj4sT3eP2zR69gNS2cFthln6MkIdaDcBaYrC2LXXIyntGakVH9A6xWDjvyiZJ/M7VluKSQabzCoo0bs+SpHrd2k7oKzCgh8fK7R+keCpto34ljuW8MeKdSgyOdqcOWemvXHEGt3rI92kkizd1x0kcRvaUCfs2nEVT0Xy5o1SJHm46LSlusd6GsreIpavaUBqubsWg78Al/rbKC04bZBXyvGf1f3QsR7sq0S1Zwjq59QjobDAXsnhp+PfrnLPHkQgU1P9FLy82f/TuwfgFJ+5FGOaM8FNhufhZGoxBRcA/r0OpEpcsu2MkVAT+BoZ6n/o2yXSTEOLX0GwwfYu3631mfwKVX/Jso7VhXQc48WrIveqPMRkptBfKeDWs7v5FpkDXSHUbWfsq5qZD9/+zcHN8cOxA8dOs/SoyNeqxSoPTWx3bB3CUzYCWXlq5YMiczrcXzqTVZMn+Bk2UeJEHhK1tAAAAAElFTkSuQmCC"
                          style="width: 15px;"></i>
                        </span>
                          : </span>
                          <span style="color: red;">
                            <mat-icon>close</mat-icon>
                          </span>
                        </div>
                      }
                    </li>


              </ol>
              @if(plan.name !== 'Trial'){
                  @if ((typeMA =="yearly")) {
                    <button class="btn btn-primary d-block mb-2 mt-3 text-capitalize" (click) ="redirectToCheckout(plan.priceID.yearly, 'subscription')">Subscribe</button>
                  
                  }
                  @if ((typeMA !=="yearly")) {
                    <button class="btn btn-primary d-block mb-2 mt-3 text-capitalize" (click) ="redirectToCheckout(plan.priceID.monthly, 'subscription')">Subscribe</button>
                  }
                }@else{
                  <button class="btn btn-primary d-block mb-2 mt-3 text-capitalize" (click) ="redirectToCheckout(plan.priceID.monthly, 'payment')">Subscribe</button>
                }
            </div>
          </div>
        </div>
      }

    </div>
  </mat-card>
</div>

</app-layout>
