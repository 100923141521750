<app-layout>
  <mat-card appearance="outlined">
    <mat-card-title>
      Add your internal pages ({{ allowed - internalPages.length + 1 }}
      remaining quotas)
    </mat-card-title>
    @for (element of internalPages; track $index) {
      <mat-card
        appearance="outlined"
        style="margin-top: 1%"
        >
        <mat-card-content>
          <form [formGroup]="element">
            <div class="row">
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    formControlName="title"
                    type="text"
                    matInput
                    placeholder="Page name"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-form-field class="full-width">
                    <input
                      formControlName="pageUrl"
                      type="text"
                      matInput
                      placeholder="Page URL"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="d-flex justify-content-center">
                      <b>Device to monitor on:</b>
                    </div>
                    <div class="d-flex justify-content-center">
                      (Check at least one checkbox) <span class="highlight">*</span>
                    </div>
                    <div class="row justify-content-around">
                      <div class="col-4">
                        <div class="row justify-content-between">
                          <div class="col-4">
                            <mat-checkbox
                              color="primary"
                              class="custom-control-input"
                              formControlName="deviceMobile"
                              id="mobileCheckbox"
                            ></mat-checkbox>
                            <label class="custom-control-label" for="mobileCheckbox">
                              <b> Mobile </b>
                            </label>
                          </div>
                          <div class="col-4">
                            <mat-checkbox
                              color="primary"
                              class="custom-control-input"
                              formControlName="deviceDesktop"
                              id="desktopCheckbox"
                            ></mat-checkbox>
                            <label class="custom-control-label" for="desktopCheckbox">
                              <b> Desktop </b>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        }
        <mat-card-action>
          <div style="display: flex">
            <div style="margin: 1%">
              <button
                (click)="addRow()"
                mat-raised-button
                [disabled]="allowed - internalPages.length === 0"
                >
                ({{ allowed - internalPages.length }})Add page
                <mat-icon>add</mat-icon>
              </button>
            </div>
            <div style="margin: 1%">
              <button (click)="deleteRow()" mat-raised-button color="accent">
                Delete page <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
          <div style="margin: 1%">
            <button (click)="save()" mat-raised-button color="primary">
              Submit
            </button>
          </div>
        </mat-card-action>
      </mat-card>
    </app-layout>
    