import { Injectable } from '@angular/core';
//import { ChartOptionsCrux } from 'src/app/features/home/dashboard/dashboard.component';

@Injectable({
  providedIn: 'root'
})
export class ChartOptionsService {



  constructor() { }

  pageSpeedData(data : any){
    return {
       series: [
         {
           name: 'Good',
           data:  [data.good],
         },
         {
           name: 'Needs improvement',
           data: [data.needsImprovement],
         },
         {
           name: 'Poor',
           data:  [data.poor],
         },
       ],chart: {
         type: 'bar',
         height: 200,
         stacked: true,
         stackType: '100%',
       },
       plotOptions: {
         bar: {
           horizontal: true,
         },
       },
       stroke: {
         //width: 2,
         colors: ['#fff'],
       },
       title: {
         text: "",
       },
       xaxis: {
         categories: [''],
       },
       tooltip: {
         y: {
           formatter: function (val) {
             return val + 'K';
           },
         },
       },
       fill: {
         colors: ['#43B399', '#F6AD45', '#EF6F52'],
       },
       legend: {
         show: false,
       },
       labels: {
         colors: undefined,
         useSeriesColors: false,
       },
     };
  }

  cruxData(data : any) {
    return {
      series: [
        {
          name: 'Good < 1.8s',
          data: [data.good],
        },
        {
          name: 'Moderate',
          data: [data.needsImprovement],
        },
        {
          name: 'Poor > 3.0s',
          data: [data.poor],
        }
      ],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
        },
      },
      xaxis: {
        type: 'category',
        categories: [
          '01/2011',
          '02/2011',
          '03/2011',
          '04/2011',
          '05/2011',
          '06/2011',
        ],
      },
      legend: {
        position: 'right',
        offsetY: 20,
      },
      fill: {
        opacity: 1,
        colors: ['#43b399', '#f6ad45', '#ef6f52'],
      },
      stroke: {
        width: 5,
      },
    };
  }

  scoreGraph(){
    return  {
      seriesscoreGraph: [
        {
          name: 'Score',
          data: [],
        },
      ],
      chartscoreGraph: {
        type: 'area',
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabelsscoreGraph: {
        enabled: false,
      },
      strokescoreGraph: {
        curve: 'straight',
      },

      titlescoreGraph: {
        text: 'Score',
        align: 'left',
      },
      subtitlescoreGraph: {
        text: '',
        align: 'left',
      },
      labelsscoreGraph: [],
      xaxisscoreGraph: {
        type: 'datetime',
      },
      yaxisscoreGraph: {
        opposite: true,
      },
      legendscoreGraph: {
        horizontalAlign: 'left',
      },
    };
  }

  scoreRadialBar(){
    return {
      seriesScoreRadialBar: [],
      chartScoreRadialBar: {
        height: 350,
        type: 'radialBar',
      },
      plotOptionsScoreRadialBar: {
        radialBar: {
          hollow: {
            size: '70%',
            background: '#293450',
          },
        },
      },

      labelsScoreRadialBar: ['Score'],
    };
  }

  chartOptionsOverview(data: any) {
    return {
      series: [
        {
          name: 'Good',
          data: [data?.good],
          
        },
        {
          name: 'Needs improvement',
          data: [data?.need],
        },
        {
          name: 'Poor',
          data: [data?.poor],
        },
     
      ],
      chart: {
        type: 'bar',
        height: 200,
        stacked: true,
      },
      annotations: {
        yaxis: [
          {
            y: 75,
            borderColor: '#00E396',
            label: {
              borderColor: '#00E396',
              style: {
                color: '#fff',
                background: '#00E396'
              },
              text: 'Y-axis annotation on 8800'
            }
          }
        ]
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      title: {
        text: '',
      },
      xaxis: {
        categories: [''],
      },
      yaxis: {
        max: 100,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return Math.round(val.toFixed());
          },
        },
      },
      fill: {
        colors: ['#15cd6a', '#fba400', '#fa4f44'],
      },
      legend: {
        show: false,
      },
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
    };
  }

  chartOptionsCompare(metricData, name) {

    let data = metricData?.histogramCompare
    let p75 = (metricData?.p75 !== undefined) ? metricData?.p75 : null;
    let absciss = metricData?.start
    let seuil = metricData?.seuil;
    if (name === 'cls' && !!seuil){
      seuil.a = seuil?.a?.toString()
      seuil.b = seuil?.b?.toString()
    }
    let title = name.toLocaleUpperCase() +  ' Histogram (%)'
    return {
      // colors: ['#000000'],
      // background: ["#ffffff"] ,

      series: data,
      chart: {
        height: 350,
        type: 'line',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        //width: 3,
        curve: 'straight',
        // dashArray: [0, 8, 5],

        colors: ["#340F07","#5D77D0","#9e94d4", "#dbcb51", "#db51d9"],

      },
      title: {
        text: title,
        align : 'left',
      },
      legend: {
        labels: {
          opacity: 0,

          colors: ["#340F07","#5D77D0","#9e94d4", "#dbcb51", "#db51d9"],
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: '#fff',

          fillColors: ["#340F07","#5D77D0","#9e94d4", "#dbcb51", "#db51d9"],
        },
      },

      xaxis: {

        categories: absciss,
      },
      annotations: {
        xaxis: [
          {
            x: p75,
            strokeDashArray: 0,
            borderColor: "#775DD0",
            label: {
              borderColor: "#775DD0",
              style: {
                color: "#fff",
                background: "#775DD0"
              },
              orientation: 'horizontal',
              text: "p75"
            }

          },
          {
            x: seuil?.a,
            strokeDashArray: 0,
            borderColor: '#15cd6a',
            label: {
              borderColor: '#15cd6a',
              style: {
                color: "#fff",
                background: '#15cd6a'
              },
              //orientation: 'horizontal',
              text: "Good-Average"
            }

            },
            {
              x: seuil?.b,
              strokeDashArray: 0,
              borderColor: '#fa4f44',
              label: {
                borderColor: '#fa4f44',
                style: {
                  color: "#fff",
                  background: '#fa4f44'
                },
                //orientation: 'horizontal',
                text: "Average-Poor"
              }

            },
        ]
      },

      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
        fillSeriesColor: false,
        enabled: true,
        enabledOnSeries: true,
        shared: true,
        followCursor: true,
        inverseOrder: false,
        // custom: undefined,
        theme: true,
        marker: {
          show: true,
        },
      },

      grid: {
        show: false,
        // fillColors: ['#15cd6a', '#fba400', '#fa4f44','#340F07','F0D1CB',"#C70039","#DDEA19"],
      },
    };
  }

  chartOptionsUxr(data, date) {

    let title = 'UXR evolution'
    return {
      // colors: ['#000000'],
      // background: ["#ffffff"] ,

      series: [
        {
          name: 'Good',
          data: data,
        }],
      chart: {
        height: 350,
        type: 'line',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        // curve: 'straight',
        // dashArray: [0, 8, 5],

        colors: ["#340F07","#5D77D0","#9e94d4", "#dbcb51", "#db51d9"],

      },
      title: {
        text: title,
        align: 'left',
      },
      legend: {
        labels: {
          opacity: 0,

          colors: ["#340F07","#5D77D0","#9e94d4", "#dbcb51", "#db51d9"],
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: '#fff',

          fillColors: ["#340F07","#5D77D0","#9e94d4", "#dbcb51", "#db51d9"],
        },
      },

      xaxis: {
        labels: {
          trim: false,
        },
        categories: date,
      },

      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
        fillSeriesColor: false,
        enabled: true,
        enabledOnSeries: true,
        shared: true,
        followCursor: true,
        inverseOrder: false,
        // custom: undefined,
        theme: true,

        marker: {
          show: true,
        },
      },

      grid: {
        show: false,
        // fillColors: ['#15cd6a', '#fba400', '#fa4f44','#340F07','F0D1CB',"#C70039","#DDEA19"],
      },
    };
  }
  chartOptionsOverviewAll(data, title, legend) {

    //let typetableau = ["Mobile", "Desktop"]
    //console.log(data.device);
    let tit = title.map(t => t[0].toUpperCase())
    
    return {
      series: [
        {
          name: legend.good,
          data: data.good,

        },
        {
          name: legend.moderate,
          data: data.need,
        },
        {
          name: legend.poor,
          data: data.poor,
        },
      ],
      chart: {
        type: 'bar',
        height: 300,
        stacked: true,
        // stackType: "100%"
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#15cd6a', '#fba400', '#fa4f44'],
      },
      //chartLabels: title,
      xaxis: {
        categories: tit,
        title : {
          enabled: true,
          text: "M: Mobile || D: Desktop",
          style: {
              fontWeight: 'normal'
          },
        },
        labels: {
          rotation : 90,
        },
        max: 100,
      },
      yaxis: {
        title:  title

      },
      /*
      yaxis: {
        max: 100,
      },*/
      tooltip: {
        y: {
          formatter: function (val) {
            return val + '%';
          },
        },
      },
      fill: {
        opacity: 1,
        colors: ['#15cd6a', '#fba400', '#fa4f44'],
      },
      legend: {
        show : false,
      },
    };
  }

}
