import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { SpeetalsDatabaseService } from 'src/app/services/common/speetalsDatabase/speetals-database.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  CruxDataApi,
  Plan,
  WebsiteAnalyze,
} from 'src/app/shared/models/user.models';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { HelperService } from 'src/app/shared/helper/helper.service';
import { countries } from 'src/app/shared/models/country.model';
import { Country } from '@angular-material-extensions/select-country';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../../services/core/auth/auth.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';

import { tap } from 'rxjs/operators';
import { UserService } from 'src/app/services/common/user/user.service';
import { SenderVarService } from 'src/app/services/senderVar.service';
import {
  belongOriginDomainValidator,
  diffFromOriginDomainValidator,
} from '../new-page/check-url-validators';
import { requireCheckboxesToBeCheckedValidator } from '../new-page/check-Validators';
import { PageSpeedInsigthService } from 'src/app/services/common/pagespeedinsigth/page-speed-insigth.service';
import { ChartOptionsService } from 'src/app/services/common/chart/chart-options.service';
import { MatRadioChange } from '@angular/material/radio';
import { environment } from 'src/environments/environment';

declare let google: any;
interface Page {
  title: string;
  url: string;
}

@Component({
  selector: 'app-monitor-site',
  templateUrl: './monitor-site.component.html',
  styleUrls: ['./monitor-site.component.scss'],
})
export class MonitorSiteComponent implements OnInit {
  @ViewChild('select') select: MatSelect;
  //
  isLinear = false;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup: UntypedFormGroup;
  //
  private curxDataApi = new CruxDataApi();
  public websiteAnalyze = new WebsiteAnalyze();
  public countries: any = countries;
  Country: any;
  username: any;
  urlVerfied: false;
  b = true;
  removable = true;
  addOnBlur = true;
  allSelected = true;
  selectedValue: String[] = ['Mobile', 'Desktop'];
  loading: string = 'true';
  submitted = false;
  public newPageFormGroup: UntypedFormGroup;
  //public competitorsForm: FormGroup;
  public competitors: UntypedFormArray;
  public internalUrls: UntypedFormArray;
  competitionBouton = true;
  internalButtom = true;
  validatorInternal = true;
  locationButton: boolean;
  elementduplicates: any;
  regex: string;
  device: string;
  chipInput: any;
  superUserId: string;
  urlCtrl: any;
  x: any;
  apiURL: string;
  country1: any;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  intUrl: Page;
  compet: Page;
  test: Array<Page> = [];
  plan: Plan;
  planView = false;
  websitesRestantes = 0;
  cptCompet = 0;
  cptInter = 0;

  groupValidators: any;
  edit: boolean;
  editWebsite: WebsiteAnalyze;
  editDataLoading = true;
  validIn: boolean = false;
  validComp: boolean = false;
  text: string;
  intUrlBack: any;
  userId: string;
  titreessai = 'titletest';
  firstFormGroupEdit: UntypedFormGroup;
  domainButtom: boolean = false;
  subBut: boolean = true;
  showResult: boolean = false;
  metrics = ['lcp', 'cls', 'fcp', 'inp', 'ttfb'];
  chartOptions: any = {};
  status: any;
  passOrNot: any;
  improvement: any;
  ptVirgule: any;
  color: any;
  rawData: any;
  result: any = {};
  title: any = {};
  gmuId: any;
  accessToken = localStorage.getItem(
    ConstantService.localStorageKeys.access_Token
  );
  saving = false

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private SpeetalsDatabaseService: SpeetalsDatabaseService,
    private websiteAnalyzeService: WebsiteAnalyzeService,
    private helperService: HelperService,
    private _formBuilder: UntypedFormBuilder,
    private pageSpeedInsigthService: PageSpeedInsigthService,
    private router: Router,
    private senderVarService: SenderVarService,
    private activatedRoute: ActivatedRoute,
    private chartOptionsService: ChartOptionsService
  ) {}
  removeTrailing(string) {
    return string.replace(/\/+$/g, '');
  }

  private internalUrlValidators = [
    //Validators.required,
    Validators.pattern(
      '^(http(s)?://)([\\da-z.-?]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
    ),
    RxwebValidators.unique(),
  ];

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.userId = localStorage.getItem(
        ConstantService.localStorageKeys.userId
      );

      this.regex = '^(http(s)?://)([\\da-z.-]+)\\.([a-z.]{2,6})';
      this.firstFormGroup = this._formBuilder.group({
        title: ['', [Validators.required]],
        domain: ['', [Validators.required, Validators.pattern(this.regex)]],
        device: new UntypedFormGroup(
          {
            myCheckbox1: new UntypedFormControl(true),
            myCheckbox2: new UntypedFormControl(true),
          },
          requireCheckboxesToBeCheckedValidator()
        ),
      });
      this.firstFormGroupEdit = this._formBuilder.group({
        title: [''],
        domain: [''],
      });

      this.secondFormGroup = this._formBuilder.group({
        internalUrls: this.formBuilder.array([this.createInternalUrl()]),
      });
      this.thirdFormGroup = this._formBuilder.group({
        competitors: this.formBuilder.array([this.createCompetitor()]),
      });

      if (params.domainName ) {
        //this.firstFormGroup.get('title').setValue(params.websiteName);
        this.firstFormGroup.get('domain').setValue(params.domainName, {
          emitEvent: false,
        });
      }
    });
  }
  validInternal() {
    this.validIn = true;
  }
  validCompetitor() {
    this.validComp = true;
    this.logValue();
  }

  navigateHome() {
    this.router.navigate(['/auth/login']);
  }

  urlValidators(regex: string) {
    return [
      Validators.pattern(regex),
      Validators.required,
      RxwebValidators.unique(),
      diffFromOriginDomainValidator(this.firstFormGroup.controls['domain']),
    ];
  }

  conditionalRequired(condition) {
    return condition ? Validators.required : Validators.nullValidator;
  }

  addUrlValidatorsRequired(fb: UntypedFormGroup, validators) {
    fb.get('title')
      .valueChanges.pipe(
        tap((titleValue: boolean) => {
          fb.get('url').setValidators(
            validators.concat(this.conditionalRequired(titleValue))
          );
          fb.get('url').updateValueAndValidity();
        })
      )
      .subscribe();
  }
  addTitleValidatorsRequired(fb: UntypedFormGroup) {
    fb.get('url')
      .valueChanges.pipe(
        tap((value: boolean) => {
          fb.get('title').setValidators(this.conditionalRequired(value));
          fb.get('title').updateValueAndValidity();
        })
      )
      .subscribe();
  }

  get competitorControls() {
    return this.thirdFormGroup.get('competitors')['controls'];
  }

  createCompetitor(): UntypedFormGroup {
    let validators = this.urlValidators(this.regex);
    let fb = this.formBuilder.group({
      title: ['', Validators.required],
      url: ['', validators],
    });
    return fb;
  }

  addCompetitor(): void {
    console.log('cpt', this.cptCompet);
    console.log('mode control', this.competitorControls[this.cptCompet]);
    if (this.competitorControls[this.cptCompet].invalid) {
      return;
    }
    if (this.cptCompet + 1 >= Number(this.plan.details.nbCompetitor.value))
      this.competitionBouton = false;
    else {
      this.competitors = this.thirdFormGroup.get('competitors') as UntypedFormArray;
      this.competitors.push(this.createCompetitor());
      this.cptCompet++;
    }
  }

  removeCompetitor(i: number) {
    if (i > 0) {
      this.competitors.removeAt(i);
      this.competitionBouton = true;
      this.cptCompet--;
    }
  }

  get internalUrlControls() {
    return this.secondFormGroup?.get('internalUrls')['controls'];
  }

  createInternalUrl(): UntypedFormGroup {
    //'^(http(s)?://)([\\da-z.-]+)\\.([a-z.]{2,6})'
    //let regexIntUrl = '^(http(s)?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    let regexIntUrl =
      '[(http(s)?)://(www\\.)?a-z-A-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)';
    let validators = this.urlValidators(regexIntUrl).concat(
      belongOriginDomainValidator(this.firstFormGroup.controls['domain'])
    );
    let fb = this.formBuilder.group({
      title: ['', Validators.required],
      url: ['', validators],
    });

    return fb;
  }

  addCount(i) {
    if (this.edit && i === 0) {
      i++;
      return true;
    } else {
      return false;
    }
  }

  addInternalUrl(): void {
    if (this.internalUrlControls[this.cptInter].invalid) {
      return;
    }
    if (this.cptInter + 1 >= Number(this.plan.details.nbInternalPage.value))
      this.internalButtom = false;
    else {
      this.internalUrls = this.secondFormGroup.get('internalUrls') as UntypedFormArray;
      this.internalUrls.push(this.createInternalUrl());
      this.cptInter++;
    }
  }

  removeInternalUrl(i: number) {
    if (i > 0) {
      this.internalUrls.removeAt(i);

      this.internalButtom = true;
      this.cptInter--;
    }
  }

  getValidity(i) {
    this.validatorInternal = !this.internalUrlControls[i].invalid;

    return !this.internalUrlControls[i].invalid;
  }

  logValue() {
    this.websiteAnalyze.internalURLs = [];
    this.websiteAnalyze.competitors = [];
    this.websiteAnalyze.originDomain = this.removeTrailing(
      this.firstFormGroup.value.domain
    );

    //Add WSA internal title

    this.websiteAnalyze.title = this.firstFormGroup.value.title;

    //Add WSA internal Url

    this.internalUrlControls.map((element) => {
      if (element.status === 'VALID')
        this.websiteAnalyze.internalURLs.push(element.value);
      // this.websiteAnalyze.internalTitle.push(element.value.title);
    });

    this.competitorControls.map((element) => {
      if (element.status === 'VALID')
        this.websiteAnalyze.competitors.push(element.value);
      // this.websiteAnalyze.competitorsTitle.push(element.value.title);
    });
    //Add WSA Country

    this.websiteAnalyze.country =
      this.country1 != undefined
        ? this.country1.alpha2Code.toLowerCase()
        : 'NotAllowed';

    //wsa Device

    this.websiteAnalyze.deviceDesktop = false;
    this.websiteAnalyze.deviceMobile = false;

    if (this.firstFormGroup.value.device.myCheckbox1)
      this.websiteAnalyze.deviceMobile = true;
    if (this.firstFormGroup.value.device.myCheckbox2)
      this.websiteAnalyze.deviceDesktop = true;

    //wsa userId
    this.websiteAnalyze.userId = localStorage.getItem(
      ConstantService.localStorageKeys.userId
    );
  }

  selectChangeHandler(event: any) {
    this.country1 = event.target.value;
  }

  onCountrySelected(country: Country) {
    this.country1 = country;
  }
  newPage() {
    this.subBut = false;

    this.websiteAnalyzeService
      .postGuestUserWebsiteAnalyze(this.websiteAnalyze)
      .subscribe((response) => {
        this.rawData = response;
        this.device = 'desktop';
        this.result = this.rawData[this.device];
        this.gmuId = response.guestDataId;
        this.buildChartOptions(this.device);
      });
    //this.authService.updateUserLoggedNbWebsites();
  }

  selectionChanged(item) {
    this.device = item.value;
  }

  clearData() {
    this.authService.logoutUser();
  }
  buildChartOptions(device: string) {
    const deviceData = this.rawData[device];

    for (let property of this.metrics) {
      if (property === 'ttfp') property = 'ttfb';

      this.title[property] = this.pageSpeedInsigthService.getTitle2(
        deviceData[property].title
      );

      this.chartOptions[property] =
        this.chartOptionsService.chartOptionsOverview(deviceData[property]);
    }
    this.status = deviceData.status;
    this.passOrNot = this.status === 'passes';

    if (this.passOrNot && deviceData?.arrayNeedImprovements?.length) {
      deviceData?.arrayNeedImprovements.map((elt) => {
        let ptVirgule =
          elt ===
          deviceData.arrayNeedImprovements[
            deviceData.arrayNeedImprovements.length - 1
          ]
            ? ' '
            : ',';
        this.improvement +=
          elt.metric.toUpperCase() + ' (' + elt.value + '%)' + ptVirgule + ' ';
      });
    }

    this.color = deviceData.color;
    Object.keys(this.chartOptions).forEach((key) => {
      const series = this.chartOptions[key].series;
      let hasNoData = true;
      series.forEach((i) => {
        if (i?.data?.[0] !== 0) {
          hasNoData = false;
        }
      });
      this.chartOptions[key]['hasNoData'] = hasNoData;
      this.showResult = true;
    });
  }

  saveData() {
    this.saving = true
    this.websiteAnalyzeService
      .saveDataForLoggedInUser(this.gmuId, this.userId)
      .subscribe((response: any) => {
        if (response.planId) {
          window.location.href =
            window.location.origin +
            '/home/buyPlan?gmuId=' +
            this.gmuId +
            '&planId=' +
            response.planId;
        } else if(response.saved) {
          window.location.href = window.location.origin + '/home';
        }
      });
  }

  setDeviceUrl(event: MatRadioChange) {
    const deviceValue = event.value;

    this.device = deviceValue;
    localStorage.setItem(ConstantService.loadingUrl.device, deviceValue);

    this.improvement = ' ';
    this.result = this.rawData[this.device];
    this.buildChartOptions(this.device);
  }

  printUxr(uxr: number) {
    return uxr !== null && uxr !== undefined;
  }

  getBadgeColor(x: number): string {
    return x < 0 ? 'warn' : 'accent';
  }

  descriptionDiff(name: string): string {
    return 'Monthly change of Good ' + name.toUpperCase() + ' %';
  }

  signupAndSaveData() {
    this.router.navigate(['/auth/login'], {
      queryParams: { gmUid: this.gmuId },
    });
  }
}
